<template>
    <div class="flex justify-center items-center mt-4">
      <div class="w-full max-w-md">
        <div class="bg-white rounded-lg shadow-md">
          <router-link
            :to="{ name: 'DetailDriver', params: { code: order.booking_id } }"
          >
            <div class="p-4">
              <div class="flex bg-primary text-white p-2 rounded-t-lg mb-4">
                <h2 class="text-md font-semibold w-[95vh]">
                  {{ order.customer_name || "" }}
                </h2>
                <div class="text-end w-[5vh]">
                  <i
                    v-if="order.transfer_type === 'Transfer Departure'"
                    class="fa-solid fa-plane-departurep"
                  ></i>
                  <i
                    v-else-if="order.transfer_type === 'Transfer Arrival'"
                    class="fa-solid fa-plane-arrival"
                  ></i>
                </div>
              </div>
              <div class="flex justify-between items-center mb-2 text-gray-500">
                <div class="flex items-center mb-2 md:mb-0">
                  <i class="far fa-calendar-alt text-gray-500 mr-2"></i>
                  <span class="text-sm">{{ order.booking_date }}</span>
                </div>
                <div class="flex items-center mb-2 md:mb-0">
                  <i class="far fa-clock text-gray-500 mr-2"></i>
                  <span :class="['text-sm', statusColorClass]">{{
                    order.booking_time
                  }}</span>
                </div>
                <div class="flex items-center mb-3">
                  <span
                    :class="[
                      'text-button',
                      'text-md',
                      'font-semibold',
                      'font-normal',
                      statusColorClass,
                    ]"
                    >{{ order.booking_status }}</span
                  >
                </div>
              </div>
              <div class="">
                <p class="text-sm font-semibold">
                  #{{ order.reference_number || "" }}
                </p>
              </div>
            </div>
          </router-link>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    props: {
      order: Object,
    },
    computed: {
      statusColorClass() {
        switch (this.order.booking_status) {
          case "NEW":
            return "text-indigo-500";
          case "COMPLETED":
            return "text-button";
          case "REJECTED":
            return "text-rose-600";
          case "COMPLETED*":
            return "text-rose-600";
          default:
            return "text-yellow-500";
        }
      },
    },
  };
  </script>
  
  <style scoped></style>
  
 