<template>
  <section>
    <div
      class="font-poppin bg-cover bg-center h-screen flex md:items-center md:justify-center"
      :style="{ backgroundImage: bgImage }"
    >
      <div class="max-w-sm mx-auto bg-white mt-5 md:mb-12 mb-[100px] p-10 rounded-2xl shadow-2xl">
        <div class="pb-5">
          <div
            class="text-heading mx-6 text-3xl  font-bold text-center font-poppins"
          >
            <h2>Tour East Supplier</h2>
          </div>
          <div>
            <p
              class="text-lg m-4 mb-2 font-normal text-center text-input"
            >
              Login to your Supplier Portal
            </p>
          </div>
        </div>
        <div class="mb-5">
          <label for="name" class="block mb-2 text-lg font-normal text-input"
            >User Name</label
          >
          <input
            v-model="supplierCode"
            type="text"
            id="name"
            class="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-md rounded-lg block w-full p-2.5"
            required
          />
        </div>
        <div class="mb-5">
          <label for="password" class="block mb-2 text-lg font-normal text-input"
            >Your password</label
          >
          <div class="relative">
            <input
              v-model="supplierPassword"
              type="password"
              id="password"
              class="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-md rounded-lg block w-full p-2.5 pr-10"
              required
            />
            <button
              type="button"
              @click="togglePasswordVisibility"
              class="absolute inset-y-0 right-0 px-3 py-1.5 text-lg text-gray-400 focus:outline-none"
            >
              <i :class="passwordVisibility ? 'fa-solid fa-eye-slash' : 'fa-solid fa-eye'"></i>
            </button>
          </div>
        </div>
        <div class="flex justify-end mt-2">
          <a
            href="#"
            class="text-md text-blue-500 hover:underline focus:outline-none"
            >Forgot Password?</a
          >
        </div>
        <div class="flex justify-center mt-8">
          <button
            @click="handleLogin"
            type="submit"
            class="rounded-lg px-12 bg-button py-2 font-popins font-normal text-lg shadow-lg text-white hover:bg-input focus:outline-none"
          >
            Login
          </button>
        </div>
      </div>
      <div v-if="errorMessage" class="error-popup text-lg font-medium">
        {{ errorMessage }}
      </div>
    </div>
  </section>
</template>

<script>
import RepositoryFactory from "../repositories/RepositoryFactory";
import AuthenticationHelper from "../helpers/AuthenticationHelper";
const Suppliers = RepositoryFactory.get("supplier");
export default {
  name: "LoginScreen",
  data() {
    return {
      bgImage: `url(${require("@/assets/bg.png")})`,
      supplierCode: "",
      supplierPassword: "",
      errorMessage: "",
      appTitle: process.env.VUE_APP_NAME,
      passwordVisibility: false,
    };
  },
  created() {
    this.checkLoggedIn();
  },
  methods: {
    checkLoggedIn() {
      if (AuthenticationHelper.checkUserAuthentication(this.$store.state)) {
        this.redirectBasedOnRole();
      }
    },
    handleLogin() {
      if (!this.isValidInput()) {
        this.errorMessage = "Please fill in all fields correctly.";
        setTimeout(() => {
          this.errorMessage = "";
        }, 3000);
        return;
      }
      this.verifyUserLogin();
    },
    isValidInput() {
      return (
        this.supplierCode.trim() !== "" && this.supplierPassword.trim() !== ""
      );
    },
    async verifyUserLogin() {
      try {
        const { data } = await Suppliers.verifyLogin(
          this.supplierCode,
          this.supplierPassword
        );
        if (data.statuscode === process.env.VUE_APP_API_STATUS_SUCCESS) {
          this.$store.commit("supplierLogIn", data.output);
          this.$root.$emit("LoggedIn");
          this.redirectBasedOnRole();
        } else {
          this.errorMessage = "Invalid credentials. Please try again.";
          this.clearErrorMessage();
        }
      } catch (error) {
        console.error("Error occurred during login:", error);
        this.errorMessage = "An error occurred during login. Please try again later.";
        this.clearErrorMessage();
      }
    },
    clearErrorMessage() {
      setTimeout(() => {
        this.errorMessage = "";
      }, 3000);
    },
    togglePasswordVisibility() {
      this.passwordVisibility = !this.passwordVisibility;
      const passwordField = document.getElementById('password');
      if (this.passwordVisibility) {
        passwordField.type = 'text';
      } else {
        passwordField.type = 'password';
      }
    },
    redirectBasedOnRole() {
      
      const role = this.$store.state.authentication.supplierType;
      if (role === 'Driver') {
        console.log("role is 1", role);
        localStorage.setItem('current_user_type','driver');
        this.$router.push({ name: 'TodayBooking' });
      } else if (role === 'Transport Supplier') {
        console.log("role is 2", role);
        localStorage.setItem('current_user_type','own vehicle');
        this.$router.push({ name: 'TodayBooking' });
      } else {
        console.log("role is 3", role);
        localStorage.setItem('current_user_type','restuarant');
        this.$router.push({ name: 'ScanSupplierCode' });
        
      }
    }
  },
};
</script>

<style scoped>
.error-popup {
  position: fixed;
  top: 20px;
  left: 50%;
  color:white;
  transform: translateX(-50%);
  background-color: #a0a0a0;
  padding: 10px;
  border-radius: 15px;
  box-shadow: 0 2px 4px rgba(219, 224, 220, 0.1);
  z-index: 9999;
}
</style>
