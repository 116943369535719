<template>
  <div class="flex flex-col justify-center shadow-md mt-2">
    <div v-if="showMenu && isAuthenticated" class="flex justify-center mb-2"></div>
    <div class="flex justify-center mb-4" v-if="!showVerifiedSuccess">
      <div class="w-4/5 lg:w-2/3">
        <div class="bg-white rounded-lg lg:px-44 lg:py-6 px-2 py-1 mt-0">
          <div class="mb-4 text-center">
            <div class="flex flex-col">
              <button @click="showDialog = true" class="bg-button hover:bg-input text-white px-3 py-2 mt-6 rounded-lg md:mx-44 mx-4">Scan QR Code</button>
              <p class="mt-3">or</p>
              <a @click="toggleManualInput" class="text-blue-500 cursor-pointer mt-3">Enter Voucher Code</a>
            </div>
            <div v-if="showManualInput" class="flex items-center justify-center mt-4">
              <input v-model="voucherCode" type="text" placeholder="Enter Voucher Code" class="border rounded p-2 w-3/4" />
              <button @click="onManualSubmit" class="bg-button hover:bg-input text-white px-3 ml-2 py-2 mt-1 rounded-lg">Redeem</button>
            </div>
          </div>
          <div v-if="scanButton" class="mb-4">
            <div class="flex justify-center align-center relative">
              <div class="mb-4">
                <div class="rounded-lg border border-gray-300 shadow-md relative">
                  <qrcode-stream @detect="onDetect" :paused="!scanButton" />
                </div>
              </div>
            </div>
            <div class="text-right">
              <button @click="hideCamera" class="text-red-500">Close</button>
            </div>
          </div>
          <div class="relative">
            <div v-if="showLoading" class="absolute inset-0 flex justify-center items-center bg-black bg-opacity-50 text-white">
              <LoadingAnimator /> 
            </div>
          </div>
          <div v-if="showMessage" class="mb-4 px-4 py-2 rounded-lg" :class="{ 'bg-red-200': !isValid, 'bg-green-200': isValid }">
            <p class="font-normal">{{ message }}</p>
          </div>
        </div>
      </div>
    </div>
    <VerifiedSuccess v-if="showVerifiedSuccess" :show="showVerifiedSuccess" :status="'success'" :description="description" :message="message" :deal="deal" :order_id="order_id" :date_time="date_time" :count_adult="count_adult" :count_child="count_child"/>
    <SelectCaptureDialog :showDialog="showDialog" @close="showDialog = false" @select-gallery="selectFromGallery" @scan-camera="showCamera" />
    <input type="file" ref="fileInput" @change="handleFileChange" class="hidden" accept="image/*" />
  </div>

</template>

<script>
import { QrcodeStream } from 'vue-qrcode-reader';
import RepositoryFactory from '../../repositories/RepositoryFactory';
import AuthenticationHelper from '../../helpers/AuthenticationHelper';
import LoadingAnimator from '../LoadingAnimator';
import VerifiedSuccess from './VerifiedSuccess.vue';
import SelectCaptureDialog from './SelectCaptureDialog.vue';

const Suppliers = RepositoryFactory.get('supplier');

export default {
  name: 'ScanSupplierCode',
  components: {
    QrcodeStream,
    LoadingAnimator,
    VerifiedSuccess,
    SelectCaptureDialog,
  },
  data() {
    return {
      bgImage: `url(${require("@/assets/bg.png")})`,
      showMenu: true,
      isAuthenticated: true,
      showMessage: false,
      scanButton: false,
      guideID: '',
      camera: '',
      qrCode: '',
      isValid: false,
      error: '',
      message: '',
      type: this.$route.params.type,
      qrCodeType: '',
      showLoading: false,
      deal: '',
      order_id: '',
      date_time: '',
      description: '',
      voucherCode: '',
      count_child:'',
      count_adult:'',
      showManualInput: false,
      showVerifiedSuccess: false,
      showDialog: false
    };
  },
  created() {
    this.checkLoggedIn();
  },
  methods: {
    closeVerifiedSuccessModal() {
      this.showVerifiedSuccess = false;
    },
    hideCamera() {
      this.scanButton = false;
      this.qrCodeType = '';
    },
    showCamera() {
      this.scanButton = true;
      this.showMessage = false;
      this.message = '';
      this.showManualInput = false;
      this.showDialog = false;
    },
    selectFromGallery() {
      this.showDialog = false;
      this.$refs.fileInput.click();
    },
    toggleManualInput() {
      this.showManualInput = !this.showManualInput;
      this.scanButton = false;
      this.showMessage = false;
      this.message = '';
    },
    handleNavClick(menuItemId) {
      this.hideCamera();
      this.activeLink = menuItemId;
      this.showManualInput = false;
    },
    async checkLoggedIn() {
      if (!AuthenticationHelper.checkUserAuthentication(this.$store.state)) {
        this.$router.replace({ name: 'LoginScreen' });
      } else {
        const auth = AuthenticationHelper.getAuthUser(this.$store.state);
        this.guideID = AuthenticationHelper.getSupplierID(auth);
      }
    },
    async onDetect(content) {
      if (content && content[0] && content[0].rawValue) {
        this.qrCode = content[0].rawValue;
        await this.verifyCode();
      } else {
        console.error('Invalid QR code detected.');
      }
    },
    async onManualSubmit() {
      this.qrCode = this.voucherCode;
      this.showLoading = true;
      await this.verifyCode();
      this.showLoading = false;
      this.voucherCode = ''; 
    },
    async verifyCode() {
      this.turnCameraOff();
      this.showLoading = true; 
      const { data } = await Suppliers.verifySupplierQrcode(this.qrCode, this.guideID);
      this.showLoading = false; 
      this.isValid = data.statuscode === process.env.VUE_APP_API_STATUS_SUCCESS;
      if (this.isValid) {
        this.deal = data.output.deal;
        this.order_id = data.output.order_id;
        this.date_time = data.output.redemption_date;
        this.message = data.output.message;
        this.description = data.output.description;
        this.count_child = data.output.count_child;
        this.count_adult = data.output.count_adult;
        this.showVerifiedSuccess = true;
      } else {
        this.scanButton = false;
        this.message = data.msg;
        this.showMessage = true;
        setTimeout(() => {
          this.message = '';
          this.showMessage = false;
        }, 5000);
      }
    },
    turnCameraOn() {
      this.camera = 'auto';
    },
    turnCameraOff() {
      this.camera = 'off';
    },
    handleFileChange(event) {
      const file = event.target.files[0];
      if (file) {
        const reader = new FileReader();
        reader.onload = (e) => {
          this.qrCode = e.target.result;
          this.verifyCode();
        };
        reader.readAsDataURL(file);
      }
    }
  },
};
</script>