<template>
  <section>
    <div v-if="loading" class="  flex justify-center items-center lg:mt-56 mt-72">
      <LoadingAnimator />
    </div>
    <div v-else class="w-auto ">
      <div class="font-sans flex justify-center items-center min-h-[90vh] bg-gray-100 p-1 md:p-4 ">
        <div class="w-full max-w-sm rounded-lg overflow-hidden shadow-md bg-white pb-6">
    
          <DetailCardComp
            :driverDetails="driverDetails"
            :bookingService="bookingDetails?.booking_services?.[0] || {}"
            :tripDetails="tripDetails"
          />
          <div class="flex justify-center">
        <div v-if="bookingDetails.booking_status.toLowerCase() === 'completed'">
          <span class="text-green-600 text-md font-normal "
            >Successfully Completed</span
          >
        </div>
        <div v-else class="justify-between flex" >
          <div
            v-if="
              showStatusButton &&
              bookingDetails.booking_status.toLowerCase() !== 'rejected' &&
              bookingDetails.booking_status.toLowerCase() !== 'completed*'
            "
          >
          <button
           class="bg-back text-white rounded text-xs py-2 px-3 flex-grow hover:bg-green-800 transition-colors duration-300"
          @click="statusBooking()">
          {{ showStatus }}
          </button>
          </div>
          <div
          v-if="
            bookingDetails.booking_status.toLowerCase() === 'new' &&
            supplierAcceptence === 'Yes'"
        >
          <button
           class="bg-white ml-1 text-danger border border-danger rounded text-xs py-[7px] px-3 flex-grow mr-2 hover:bg-danger hover:text-white transition-colors duration-300"
            @click="saveBookingStatus('REJECTED')" 
          >Rejected</button>
      </div>
        </div>
      </div>
      <div class="flex justify-center" v-if="!loading">
        <div class="flex justify-between "
        v-if="
          showNoShowButton &&
          bookingDetails.booking_status.toLowerCase() === 'on site'
        "
      >
        <button
         
          @click="handleSwipeComplete('no show')"
          class=" bg-white text-danger border border-danger rounded text-xs py-2 px-3 flex-grow mr-1 hover:bg-danger hover:text-white transition-colors duration-300"
        >
          No Show
        </button>
      </div>
        <div v-if="  bookingDetails.booking_status.toLowerCase() === 'on site' &&
            (bookingDetails.booking_services[0].status === '' ||
              bookingDetails.booking_services[0].status === null)
          "
        >
          <button  @click=" handleSwipeComplete('pick up')" class="bg-back text-white rounded text-xs py-[9px] px-4 flex-grow hover:bg-green-800 transition-colors duration-300"> 
            Pick Up
          </button>
        </div>
        
    
        <div
          v-if="
            showNoShowButton &&
            bookingDetails.booking_status.toLowerCase() === 'on site'
          "
        >
          <button

             @click="handleSwipeComplete()"
          />
        </div>
      </div>
      <div class="flex justify-center mt-1.5" v-if="!loading">
        <button
          v-if="
            bookingDetails.booking_status.toLowerCase() === 'on site' &&
            (bookingDetails.booking_services[0].status === '' ||
              bookingDetails.booking_services[0].status === null)
          "
          @click="handleShowQRCode()"
          class="bg-transparent text-success border-none text-xs font-bold py-2 px-4 w-[142px] mt-1 hover:bg-back hover:text-white transition-colors duration-300 rounded"
        >
          Show QR Code
        </button>
        <div>
          
        </div>
      </div>
    </div>
  </div>
      <div
        v-if="showNoShowDialog"
        class="fixed top-0 left-0 w-full h-full bg-gray-500 bg-opacity-90 flex justify-center items-center"
      >
        <NoShowDialog
          :status="currentStatus"
          :booking-id="bookingID"
          :supplier-id="supplierID"
          :driver-id="driverID"
        />
      </div>
   
      <div
        v-if="showQRModal"
        class="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50"
      >
        <div class="bg-white rounded-lg shadow-lg p-6">
          <div class="flex flex-col items-center">
            <div class="text-center font-normal">
              {{
                bookingDetails.booking_services
                  ? bookingDetails.booking_services[0].qr_code
                  : ""
              }}
            </div>
            <div class="mt-4 text-center">
              <img
                :src="`${bookingDetails.booking_services ? qrCodeSrc : ''}`"
                alt="QR Code"
                class="w-64"
              />
            </div>
            <div class="mt-4 text-center text-button font-normal">
              Scan QR Code (For Customer)
            </div>
          </div>
          <div class="mt-6 flex justify-center">
            <button
              @click="showQRModal = false"
              class="px-4 py-2 text-lg font-normal bg-pink-700 hover:bg-input text-white rounded-lg m-2 focus:outline-none"
            >
              Close
            </button>
          </div>
        </div>
      </div>
    
      <div v-if="showSaveMsg" class="error-popup text-md">
        Image saving ...
      </div>
    </div>
  </section>
</template>

<script>
import DetailCardComp from "@/UpdatedScreens/DetailCardComp";
import RepositoryFactory from "../repositories/RepositoryFactory";
import AuthenticationHelper from "../helpers/AuthenticationHelper";
import LoadingAnimator from "@/components/LoadingAnimator";
import NoShowDialog from "@/components/driver/NoShowDialog.vue";
const Suppliers = RepositoryFactory.get("supplier");
export default {
  components: { NoShowDialog, LoadingAnimator, DetailCardComp},
 data() {
    return {
      supplier_type: this.$store.state.authentication.supplierType,
      mediaStream: null,
      imageCaptured: false,
      imageSource: "",
      blob: null,
      showNoShowDialog: false,
      showNoShowButton: true,
      showRetakeOption: false,
      driverDetails: {},
      tripDetails: [],
      bookingDetails: {},
      loading: true,
      latitude: "",
      longitude: "",
      supplierID: "",
      bookingID: this.$route.params.code,
      pickupFlag: false,
      driverID: "",
      showQRModal: false,
      showSaveMsg: false,
      startX: 0,
      endX: 0,
      currentStatus: this.status,
    };
  },
  async created() {
    await this.checkLoggedIn();
    await this.getBookingDetails();
    await this.locateMe();
    await this.checkPickup();
    this.loading = false;
    console.log("Supplier Acceptance:", this.supplierAcceptance);
  },
  computed: {
    supplierAcceptence() {
      return this.$store.state.authentication.supplierAcceptance;
    },
    showStatusButton() {
      if (
        (this.bookingDetails.booking_status.toLowerCase() === "on site" &&
          !this.pickupFlag) ||
        this.bookingDetails.booking_status.toLowerCase() === "completed"||
       this.bookingDetails.booking_status.toLowerCase() === 'completed*'
      ) {
        return false;
      }
      return true;
    },
    qrCodeSrc() {
      return this.bookingDetails.booking_services[0].qr_code_image;
    },
    showStatus() {
      if (this.supplierAcceptance === 'Yes') {
        if (this.bookingDetails.booking_status.toLowerCase() === "new") {
        return "Accept Job";
      }
      if (this.bookingDetails.booking_status.toLowerCase() === "confirmed") {
        return "On the Way";
      }
      if (
        this.bookingDetails.booking_status.toLowerCase() === "On the Way"
      ) {
        return "On Site";
      }
      if (
        this.bookingDetails.booking_status.toLowerCase() ===
          "on site" &&
        this.pickupFlag
      ) {
        return "Ride Complete";
      }
      if (
        this.bookingDetails.booking_status.toLowerCase() === "pax on board"
      ) {
        return "Ride Complete";
      }
      return "";
    }
    else{
      if (this.bookingDetails.booking_status.toLowerCase() === "new") {
        return "Confirmed";
      }
      if (this.bookingDetails.booking_status.toLowerCase() === "confirmed") {
        return "On the Way";
      }
      if (
        this.bookingDetails.booking_status.toLowerCase() === "on the way"
      ) {
        return "On Site";
      }
      if (
        this.bookingDetails.booking_status.toLowerCase() ===
          "on site" &&
        this.pickupFlag
      ) {
        return "Ride Complete";
      }
      if (
        this.bookingDetails.booking_status.toLowerCase() === "pax on board"
      ) {
        return "Ride Complete";
      }
      return "";
    }
  },
    setNewStatus() {
  if (this.driverDetails.acceptance === 'Yes') {
    if (this.bookingDetails.booking_status.toLowerCase() === "new") {
        return "CONFIRMED";
      }
      if (this.bookingDetails.booking_status.toLowerCase() === "confirmed") {
        return "ON THE WAY";
      }
      if (
        this.bookingDetails.booking_status.toLowerCase() === "on the way"
      ) {
        return "ON SITE";
      }
      if (
        this.bookingDetails.booking_status.toLowerCase() ===
          "on site" &&
        !this.pickupFlag
      ) {
        return "PAX ON BOARD";
      }
      if (
        this.bookingDetails.booking_status.toLowerCase() ===
          "on site" &&
        this.pickupFlag
      ) {
        return "COMPLETED";
      }
      if (
        this.bookingDetails.booking_status.toLowerCase() === "pax on board"
      ) {
        return "COMPLETED";
      }
      return "";
  } else {
    if (this.bookingDetails.booking_status.toLowerCase() === "new") {
        return "CONFIRMED";
      }
      if (this.bookingDetails.booking_status.toLowerCase() === "confirmed") {
        return "ON THE WAY";
      }
      if (
        this.bookingDetails.booking_status.toLowerCase() === "on the way"
      ) {
        return "ON SITE";
      }
      if (
        this.bookingDetails.booking_status.toLowerCase() ===
          "on site" &&
        !this.pickupFlag
      ) {
        return "PAX ON BOARD";
      }
      if (
        this.bookingDetails.booking_status.toLowerCase() ===
          "on site" &&
        this.pickupFlag
      ) {
        return "COMPLETED";
      }
      if (
        this.bookingDetails.booking_status.toLowerCase() === "pax on board"
      ) {
        return "COMPLETED";
      }
      return "";
    }
}
  },
  methods: {
    statusBooking(){
     if (this.bookingDetails.booking_status.toLowerCase() === 'on the way')
      {
      this.handleSwipeComplete('on the way');
    } else {
      this.saveBookingStatus(this.setNewStatus);
    }
  },
  handleSwipeComplete(status) {
  this.currentStatus= status
        this.showNoShowDialog = true;     
  },

  
    handleSwipe(event) {
      console.log("Swipe direction:", event.direction);
    },

    handleNotShownClick() {
      this.showNoShowDialog = true;
    },
    handleShowQRCode() {
      this.showQRModal = true;
    },
    async checkPickup() {
      let pickupFlag = true;
      this.bookingDetails.booking_services.forEach((service) => {
        if (service.status === "" || service.status === null) {
          pickupFlag = false;
        }
      });
      this.pickupFlag = pickupFlag;
    },
    async getLocation() {
      try {
        const location = await navigator.permissions.query({
          name: "geolocation",
        });
        if (location.state === "denied") {
          console.log(
            "Geolocation permission has been blocked. Please reset it in your browser settings to enable location services."
          );
          return null;
        }

        if (location.state === "granted" || location.state === "prompt") {
          return new Promise((resolve, reject) => {
            navigator.geolocation.getCurrentPosition(
              (pos) => resolve(pos),
              (err) => reject(err)
            );
          });
        }
      } catch (error) {
        console.error("Error accessing geolocation:", error);
        return null;
      }
    },
    async locateMe() {
      try {
        const location = await this.getLocation();
        this.latitude = location.coords.latitude;
        this.longitude = location.coords.longitude;
      } catch (e) {
        this.latitude = "";
        this.longitude = "";
      }
    },
    setBookingStatus(value) {
      this.newStatus = value;
    },
    async saveBookingStatus(status) {
      this.loading = true;

      await Suppliers.updateSupplierBookingStatus(
        this.bookingID,
        this.supplierID,
        this.driverID,
        status,
        this.latitude,
        this.longitude
      );

      await this.getBookingDetails();

      this.loading = false;
    },

    async getBookingDetails() {
      const bookingID = this.$route.params.code;
      const { data } = await Suppliers.getDriverBookingDetails(
        bookingID,
        this.driverID,
        this.supplier_type
      );

      if (data.statuscode === process.env.VUE_APP_API_STATUS_SUCCESS) {
        const bookingData = data.output;
        this.bookingDetails = bookingData;
        this.tripDetails = {
          bookingNumber: bookingData.booking_number,
          bookingDate: bookingData.booking_date,
          pickUpTime: bookingData.booking_time,
          totalAdults: bookingData.total_adult,
          status: bookingData.booking_status,
          pickUpLocation: bookingData.pickup_point,
          dropOffLocation: bookingData.dropoff_point,
          flightTime: bookingData.supplier_time,
          flightNumber: bookingData.flight_number,
          customerName: bookingData.name,
          phoneNumber: bookingData.flight_number,
          scheduled_time: bookingData.flight_scheduled_time,
          actual_time: bookingData.flight_actual_time,
          flight_status: bookingData.flight_status,
          transfer_type: bookingData.transfer_type,
          totalKids:bookingData.total_child,
        };
        this.driverDetails = {
          name: bookingData.name,
          image: bookingData.driver_image,
          vehicle: bookingData.vehicle_number,
          contact: bookingData.contact_number,
        };
      }
    },

    async checkLoggedIn() {
      if (!AuthenticationHelper.checkUserAuthentication(this.$store.state)) {
        this.$router.replace({ name: "LoginScreen" });
      } else {
        const auth = AuthenticationHelper.getAuthUser(this.$store.state);
        this.driverID = AuthenticationHelper.getSupplierID(auth);
      }
    },
  },
};
</script>

<style>
.no-show-dialog {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #fff;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
}
.camera-stream {
  width: 100%;
  max-width: 300px;
  height: auto;
  border-radius: 10px;
}
.error-popup {
  position: fixed;
  top: 20px;
  left: 50%;
  color: white;
  transform: translateX(-50%);
  background-color: #a0a0a0;
  padding: 10px;
  border-radius: 15px;
  box-shadow: 0 2px 4px rgba(219, 224, 220, 0.1);
  z-index: 9999;
}
</style> 

