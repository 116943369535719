import axios from 'axios';

// set API Configuration
const supplierApiBaseDomain = `${process.env.VUE_APP_BACKOFFICE_API_DOMAIN}`;
const apiBaseUrl = `${supplierApiBaseDomain}/api/index.php`;

const supplierApiAxios = axios.create({
  baseURL: apiBaseUrl,
});

const basicAxios = axios.create();

/* Generating API Authentication Headers */

async function hashHmac(type, message, key, base64) {
  const getUtf8Bytes = (str) => new Uint8Array(
    [...str].map((c) => c.charCodeAt(0)),
  );
  const keyBytes = getUtf8Bytes(key);
  const messageBytes = getUtf8Bytes(message);
  const cryptoKey = await crypto.subtle.importKey(
    'raw', keyBytes, { name: 'HMAC', hash: type },
    true, ['sign'],
  );
  const sig = await crypto.subtle.sign('HMAC', cryptoKey, messageBytes);
  const data = String.fromCharCode(...new Uint8Array(sig));
  return base64 ? btoa(data) : data;
}

function byteToHex(byte) {
  return (`0${byte.toString(16)}`).slice(-2);
}

function generateId(len = 40) {
  const arr = new Uint8Array(len / 2);
  crypto.getRandomValues(arr);
  return Array.from(arr, byteToHex).join('');
}

supplierApiAxios.interceptors.request.use(async (config) => {
  const appKey = process.env.VUE_APP_API_APP_KEY;
  const newConfig = config;
  const params = config.url.split('/');
  const controller = params[1];
  const action = params[2];

  const timestamp = new Date().getTime() / 1000;
  const nonce = generateId(20);
  const messageKey = `${appKey}-${controller.toLowerCase()}-${action.toLowerCase()}-${timestamp}-${nonce}`;
  const key = process.env.VUE_APP_API_SECRET_KEY;
  const hmac = await hashHmac('SHA-256', messageKey, key, true);

  newConfig.headers.apikey = appKey;
  newConfig.headers.accesskey = `${nonce}-${timestamp}-${hmac}`;
  return newConfig;
});

export default {
  supplierApiAxios,
  basicAxios,
};
