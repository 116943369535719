<template>
    <button class="mx-1 px-4 py-2 text-md font-normal bg-button hover:bg-input text-white rounded-lg">
      {{ buttonText }}
    </button>
  </template>
  
  <script>
  export default {
    name:'butonComp',
    props: {
      buttonText: {
        type: String,
        required: true
      }
    }
  };
  </script>