<template>
  <section>
    <!-- Navbar -->
    <div v-if="$route.name !== 'LoginScreen'" class="font-poppins">
      <nav class="bg-white py-2 md:px-12 px-2 w-full shadow z-10">
        <div class="flex items-center justify-between">
          <div
            v-if="$route.name !== 'TodayBooking'"
            class=" pt-1 py-1 w-6 z-2"
          >
            <button
              @click="navigateBack"
              class="text-gray-600 focus:outline-none"
            >
              <i class="fas fa-arrow-left text-lg"></i>
            </button>
          </div>
          <div class="flex justify-center content-center">
            <img src="@/assets/logo.svg" alt="Logo" class="md:h-8 h-6 pr-6" />
          </div>
          <div>
            <div class="relative" @click="toggleDropdown">
              <button class="text-2xl ">
                <i class="fa-regular fa-user"></i>
              </button>
              <div
                v-if="dropdownOpen"
                class="absolute right-0 mt-2 w-40 bg-white rounded-lg shadow-lg py-2 z-50"
                @click.stop
              >
                <!-- <router-link
                  to="/BookingList"
                  class="block px-4 py-2 text-gray-800 hover:bg-gray-100"
                  @click="toggleDropdown"
                >
                  Booking List
                </router-link> -->
                <router-link
                  to="/UserProfile"
                  class="block px-4 py-2 text-gray-800 hover:bg-gray-100"
                  @click="toggleDropdown"
                >
                  Profile
                </router-link>
                <button
                  @click="logOut"
                  class="block w-full text-left px-4 py-2 text-gray-800 hover:bg-gray-100 focus:outline-none"
                >
                  Logout
                </button>
              </div>
            </div>
          </div>
        </div>
      </nav>
    </div>
  </section>
</template>

<script>
import AuthenticationHelper from "../helpers/AuthenticationHelper";
import store from "../store";

export default {
  name: "NavBar",
  data() {
    return {
      dropdownOpen: false,
    };
  },
  computed: {
    isAuthenticated() {
      return AuthenticationHelper.checkUserAuthentication(store.state);
    },
    isRestaurantUser() {
      const authentication = store.state.authentication;
      return authentication && authentication.supplierType !== "Restaurant";
    },
    showCustomCalendar() {
      return this.isAuthenticated && this.isRestaurantUser;
    },
  },
  methods: {
    logOut() {
      store.commit("logOut");
      this.$router.push({ name: "LoginScreen" });
      this.toggleDropdown();
    },
    toggleDropdown() {
      this.dropdownOpen = !this.dropdownOpen;
      if (this.dropdownOpen) {
        document.addEventListener("click", this.closeDropdownOnClickOutside);
      } else {
        document.removeEventListener("click", this.closeDropdownOnClickOutside);
      }
    },
    closeDropdownOnClickOutside(event) {
      if (!this.$el.contains(event.target)) {
        this.dropdownOpen = false;
        document.removeEventListener("click", this.closeDropdownOnClickOutside);
      }
    },
    stopPropagation(event) {
      event.stopPropagation();
      this.toggleDropdown();
    },
    navigateBack() {
      this.$router.go(-1);
    },
  },
};
</script>
