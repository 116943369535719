<template>
  <section class="font-poppin bg-cover bg-center h-screen" :style="{ backgroundImage: bgImage }">
    <div class="px-4 py-4">
     
    </div>
    <div v-if="loading" class="flex justify-center items-center mt-72 lg:mt-44">
      <loadingAnimator :loading="loading" />
    </div>
    <div v-else class="max-w-sm p-2 mx-auto mt-24 md:mt-2 bg-white border border-gray-200 rounded-lg shadow ">
      <div class="flex flex-col items-center justify-center pt-4 pb-3 mx-14">
        <div class="bg-button w-26 h-26 rounded-full p-1 flex justify-center">
          <img :src="driverImage" alt="Driver Image" class="w-20  h-20 rounded-full" />
        </div>
        <p class="text-xl text-button font-normal py-2">
          {{ driverDetails.first_name }}
        </p>
      </div>
      <div class="mx-6 md:mx-8 font-poppin text-lg font-seminormal space-y-6 pt-4">
        <div v-for="(value, key) in driverDetailsInfo" :key="key" class="flex justify-between">
          <p class="font-normal">{{ key }}:</p>
          <p>{{ value }}</p>
        </div>
      </div>
      <router-link :to="{ name: 'ResetPassword' }">
        <div class="flex justify-center my-8">
          <button class="rounded-lg px-4 bg-button py-2 font-popins font-seminormal text-lg shadow-lg text-white hover:bg-input focus:outline-none">
            Reset Password
          </button>
        </div>
      </router-link>
    </div>
  </section>
</template>


<script>
import RepositoryFactory from "../repositories/RepositoryFactory";
import LoadingAnimator from "@/components/LoadingAnimator";
const Suppliers = RepositoryFactory.get("supplier");

export default {
  components: {
    LoadingAnimator,
  },
  data() {
    return {
       bgImage: `url(${require("@/assets/bg.png")})`,
      driverDetails: {},
      loading: true,
      error: null,
      current_user_type: "",
    };
  },
  async created() {
    this.current_user_type = localStorage.getItem("current_user_type");
    console.log(this.current_user_type);
    await this.getSupplierProfile();
  },
  computed: {
    driverImage() {
      return this.driverDetails.picture &&
        this.driverDetails.picture !== "" &&
        this.driverDetails.picture !== "null"
        ? this.driverDetails.picture
        : this.profilePic;
    },
    profilePic() {
      return "https://res.cloudinary.com/www-travelpakistani-com/image/upload/v1709880111/External%20Links/profile-pic.png";
    },
    driverDetailsInfo() {
      if (this.current_user_type === "driver") {
        return {
          "User Name": this.driverDetails.username,
          Language: this.driverDetails.preferred_language,
          "Vehicle #": this.driverDetails.vehicle,
          "Contact #": this.driverDetails.phone,
        };
      } else if (this.current_user_type === "own vehicle") {
        return {
          "User Name": this.driverDetails.f_name,
          Language: this.driverDetails.preferred_language,
          "Vehicle #": this.driverDetails.vehicle_number,
          "Contact #": this.driverDetails.contact_number,
        };
      } else {
        return {
          "User Name": this.driverDetails.f_name,
          Language: this.driverDetails.preferred_language,
          "Contact #": this.driverDetails.contact_number,
        };
      }
    },
  },
  methods: {
    async getSupplierProfile() {
      try {
        const supplierID = this.$store.state.authentication.supplierID;
        const supplierType = this.$store.state.authentication.supplierType;
        const { data } = await Suppliers.getSupplierProfile(
          supplierID,
          supplierType
        );
        if (data.output !== null && data.statuscode !== "E_FAIL") {
          this.driverDetails = data.output[0];
          //    this.driverDetails.acceptance = data.output[0].acceptance;
          this.loading = false;
        } else {
          this.error = "Error fetching supplier profile";
          console.error(this.error);
        }
      } catch (error) {
        this.error = "Error fetching supplier profile";
        console.error("Error:", error.message);
      }
    },
  },
};
</script>
<style scoped>
  @media screen and (max-width: 2044px) {
    body {
      overflow: hidden; 
    }
  }
</style>

