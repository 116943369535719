import Repository from './Repository';

class BookingRepository {
  constructor() {

    this.resourcePathName = '/booking';
    this.resourcePath = '/guide';
  }

  /**
   * Verifies a given qr code
   * source API Endpoint
   *
   * @param qrCode string
   * @returns {Promise<AxiosResponse<T>>}
   */
  shuttleList() {
    return Repository.supplierApiAxios.post(`${this.resourcePathName}/get_driver_assignment_notification`,
      {

        driverId:125,
booking_date:"2024-06-12"

        
      });
  }
  shuttleListUpdate(
    bookingStatus,
    latitude,
    longitude,
   ) {
    return Repository.supplierApiAxios.post(`${this.resourcePath}/update_booking_status_group`,
      {
        driver_id:124,
        booking_time:"09:30:00",
        booking_date: "2024-06-12",
        status: bookingStatus,
        lat: latitude,
        lng: longitude, 
      });
    
  }
}
export default new BookingRepository();






















// <template>
//   <div class="container p-4">
//     <!-- Date Picker -->
//     <label for="booking-date" class="block text-sm font-medium text-gray-700">Select Date</label>
//     <div class="mb-2 flex justify-center">
//       <VueDatePicker
//         v-model="selectedDate"
//         :enable-time-picker="false"
//         now-button-label="Current"
//         auto-apply
//         :format="datePickerConfig.dateFormat"
//         @update:modelValue="fetchBookings"
//       ></VueDatePicker>
//     </div>
//     <!-- Route Sections -->
//     <div v-for="route in routes" :key="route.name" class="mx-6 md:mx-2 mt-12">
//   <div class="mt-2 flex justify-between">
//     <h2 class="md:text-3xl text-lg font-bold">{{ route.name }}</h2>
//     <button @click="viewAll(route.name)" class="text-indigo-500 hover:text-indigo-700 text-md">
//       {{ route.viewAll ? "Show Less" : "View All" }}
//     </button>
//   </div>
//   <div class="grid grid-cols-1 md:grid-cols-3 gap-6 my-6">
//     <div v-for="(booking, index) in displayedBookings" :key="index" class="bg-white shadow overflow-hidden sm:rounded-lg mb-6">
//       <img
//         v-if="booking.image"
//         class="w-full h-48 object-cover object-center" 
//         :src="booking.image"
//         alt="Booking Image"
//       />
//       <img
//         v-else
//         class="w-full h-48 object-cover object-center"
//         src="https://thitbo.vivusea.com/upload/images/noimg.jpg"
//         alt="Default Image"
//       />
//       <div class="px-2 md:px-4 pt-2 pb-1">
//         <div class="text-md md:text-lg lg:text-xl font-bold text-input h-12">
//           {{ booking.hotelName }}
//         </div>
//         <p class=" font-medium text-input m-2">
//           Pick-up Time: {{ booking.pickupTime }}<br />
//           Total Adults: {{ booking.totalAdults }}<br />
//           Total Children: {{ booking.totalChildren }}
//         </p>
//       </div>
//       <div class="flex md:flex-row flex-col gap-2">
//         <!-- Update booking status buttons -->
  

//           <div class="flex md:flex-row flex-col gap-2">
//             <button @click="updateBookingStatus(booking, 'SERVICE COMPLETE')" class="bg-orange-500 hover:bg-orange-700 w-full text-white py-2 rounded" v-if="booking.bookingStatus.toLowerCase() === 'service started'">
//               Service Complete
//             </button>
//             <button @click="updateBookingStatus(booking, 'SERVICE COMPLETED')" class="bg-orange-500 hover:bg-orange-700 w-full text-white py-2 rounded" v-if="booking.bookingStatus.toLowerCase() === 'service complete'">
//               Service Completed
//             </button>
//             <button class="bg-green-500 hover:bg-green-700 w-full text-white py-2 rounded">Notify Customer</button>
//           </div>
//         </div>
//       </div>
//     </div>
//   </div>
//   </div>
// </template>

// <script>
// import VueDatePicker from "@vuepic/vue-datepicker";
// import "@vuepic/vue-datepicker/dist/main.css";
// import RepositoryFactory from "../repositories/RepositoryFactory";


// const Suppliers = RepositoryFactory.get("supplier");

// export default {
//   name: "BookingList",
//   components: { VueDatePicker },
//   data() {
//     return {
//       selectedDate: new Date().toISOString().split("T")[0],
//       routes: [],
//       latitude: "",
//       longitude: "",
//       datePickerConfig: {
//         dateFormat: "yyyy-MM-dd",
//       },
//     };
//   },
//   computed: {
//     displayedBookings(route) {
//       return route.viewAll ? route.bookings : route.bookings.slice(0, 3);
//     },
//   },
//   methods: {
//     async fetchBookings() {
//       try {
//         const response = await Suppliers.shuttleList();
//         const data = response.data;

//         if (data.statuscode === "E_SUCC") {
//           const groupedByTitle = data.output.reduce((acc, booking) => {
//             const truncatedTitle = booking.title.slice(41);

//             if (!acc[truncatedTitle]) {
//               acc[truncatedTitle] = {
//                 name: truncatedTitle,
//                 viewAll: false,
//                 bookings: [],
//               };
//             }

//             acc[truncatedTitle].bookings.push({
//               title: truncatedTitle,
//               hotelName: booking.hotel_name,
//               pickupTime: booking.pickup_time,
//               totalAdults: booking.total_adult,
//               totalChildren: booking.total_child,
//               bookingStatus: booking.booking_status,
//               clientIDs: booking.client_ids,
//               image: booking.image,
//             });

//             return acc;
//           }, {});

//           this.routes = Object.values(groupedByTitle);
//         } else {
//           console.error("API response status is not successful:", data.statuscode);
//           // Handle error case here, show a message, or reset data
//         }
//       } catch (error) {
//         console.error("Error fetching bookings:", error);
//         // Handle network or other errors here
//       }
//     },

//     async updateBookingStatus(booking, status) {
//       try {
//         // Assuming Suppliers.updateBookingStatus method takes status, latitude, and longitude
//         await Suppliers.updateBookingStatus(status, this.latitude, this.longitude);
        
//         // Update booking status locally after API call
//         booking.bookingStatus = status === 'SERVICE COMPLETE' ? 'COMPLETED' : 'SERVICE COMPLETED';

//       } catch (error) {
//         console.error("Error updating booking status:", error);
//         // Handle error here
//       }
//     },

//     viewAll(routeName) {
//       const route = this.routes.find((route) => route.name === routeName);
//       route.viewAll = !route.viewAll;
//     },

//     async getLocation() {
//       try {
//         const location = await navigator.permissions.query({
//           name: "geolocation",
//         });
//         if (location.state === "denied") {
//           console.log("Geolocation permission has been blocked. Please reset it in your browser settings to enable location services.");
//           return null;
//         }

//         if (location.state === "granted" || location.state === "prompt") {
//           return new Promise((resolve, reject) => {
//             navigator.geolocation.getCurrentPosition(
//               (pos) => resolve(pos),
//               (err) => reject(err)
//             );
//           });
//         }
//       } catch (error) {
//         console.error("Error accessing geolocation:", error);
//         return null;
//       }
//     },

//     async locateMe() {
//       try {
//         const location = await this.getLocation();
//         if (location) {
//           this.latitude = location.coords.latitude;
//           this.longitude = location.coords.longitude;
//         }
//       } catch (e) {
//         this.latitude = "";
//         this.longitude = "";
//       }
//     },
//   },

//   watch: {
//     selectedDate() {
//       this.fetchBookings();
//     },
//   },

//   async created() {
//     await this.locateMe();
//     await this.fetchBookings();
//   },
// };
// </script>
