<template>
  <router-link :to="{ name: 'DetailBooking', params: { code: order.booking_id } }">
    <div v-if="order" class="h-24 relative overflow-hidden rounded my-4 py-3 px-4 bg-zinc-50 shadow-md font-poppins mt-2">
      <div class="absolute top-6 right-0">
        <button class="bg-gray-600 h-10 w-12 mr-2 flex justify-center rounded hover:bg-gray-400">
          <i class="fa-solid fa-arrow-right text-md text-white mt-3 "></i>
        </button>
      </div>
      <div class="flex flex-col">
      <div class="flex">
        <div :class="[ 'h-6','text-gray-100', 'w-28', 'mr-2','', 'flex', 'rounded', timeColorClass]">
              <p class="ml-[6px] text-md font-normal">{{ order.booking_time }}</p>
            </div>
          <div class="flex items-center md:w-[400px] w-full">
            <div class="text-gray-600 pr-2 pb-1">
              <i v-if="order.transfer_type === 'Transfer Departure'" class="fa-solid fa-plane-departure"></i>
              <i v-else-if="order.transfer_type === 'Transfer Arrival'" class="fa-solid fa-plane-arrival"></i>
            </div>
            <p :class="['text-button', 'text-md' ,'font-normal', statusColorClass]">{{ order.booking_status }}</p>
          </div>
        </div>
      </div>
        <div class="flex flex-col">
          <p class="font-normal text-md text-gray-700">{{ order.customer_name || '' }}</p>
          <p class="font-normal text-md text-gray-700">{{ order.reference_number || '' }}</p>
        </div>
      </div>

  </router-link>
</template>

<script>
export default {
  props: {
    order: Object,
  },
  computed: {
    timeColorClass() {
      switch (this.order.booking_status) {
        case 'NEW':
          return 'bg-indigo-500';
        case 'COMPLETED':
          return 'bg-button';
        case 'REJECTED':
          return 'bg-rose-600';
        case 'COMPLETED*':
          return 'bg-rose-600';
        default:
          return 'bg-orange-400';
      }
    },
    statusColorClass() {
      switch (this.order.booking_status) {
        case 'NEW':
          return 'text-indigo-500';
        case 'COMPLETED':
          return 'text-button';
        case 'REJECTED':
          return 'text-rose-600';
        case 'COMPLETED*':
          return 'text-rose-600';
        default:
          return 'text-yellow-500';
      }
    },
  },
};
</script>
