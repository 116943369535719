<template>
    <div v-if="showDialog" class="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
      <div class="bg-white rounded-lg p-4">
        <div class="flex justify-end">
          <button @click="closeDialog" class="text-input hover:text-pink-600 font-semibold">X</button>
        </div>
        <h2 class="text-md font-normal mb-4 text-center">Choose an Option</h2>
        <div class="flex justify-center mt-4">
          <ButtonComp @click="selectFromGallery" :buttonText="'Gallery'" />
          <ButtonComp @click="scanWithCamera" :buttonText="'Camera'" />
        </div>
      </div>
    </div>
  </template>
  
  <script>
import ButtonComp from '../Common/ButtonComponent.vue';
  
  export default {
    props: {
      showDialog: {
        type: Boolean,
        required: true
      }
    },
    components: {
      ButtonComp
    },
    methods: {
      closeDialog() {
        this.$emit('close');
      },
      selectFromGallery() {
        this.$emit('select-gallery');
      },
      true() {
        this.$emit('true');
      },
      scanWithCamera() {
        this.$emit('scan-camera');
      }
    }
  };
  </script>
  
 
  