import Repository from './Repository';

class BackofficeRepository {
  constructor() {
    this.resourcePathName = '/backoffice';
  }
  /**
     * Gets site Information
     * source API Endpoint
     * @returns {Promise<AxiosResponse<T>>}
     */

  getAppInformation() {
    return Repository.supplierApiAxios.get(`${this.resourcePathName}/get_site_credentials`);
  }
  /**
     * Gets site icons
     * source API Endpoint
     * @returns {Promise<AxiosResponse<T>>}
     */

  getAppIcons() {
    return Repository.supplierApiAxios.get(`${this.resourcePathName}/get_site_icons`);
  }
}

export default new BackofficeRepository();
