<template>
  <div v-if="show && status === 'success'" class="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
    <div class="relative bg-white w-full h-full flex flex-col p-6">
      <div class="text-right">
        <button @click="navigateBack" class="text-gray-500 font-bold font-sans text-lg hover:text-pink-600">X</button>
      </div>
        <div class="flex justify-center">
          <img src="@/assets/logo.svg" alt="Logo" class="h-18 w-44 mb-4" />
        </div>
        <div class="text-center mt-14 mb-8">
          <img :src="icon" alt="Status Icon" class="w-24 h-24 mx-auto mb-2" />
          <p class="text-lg font-bold text-green-500 mb-2">{{ message }}</p>
        </div>
        <div class="text-center mb-4 font-bold text-green-500">
          <p class="font-bold text-xl">{{ deal }}</p>
          <p class="font-bold text-xl">Order ID: {{ order_id }}</p>
          <p class="text-md text-gray-700 py-1">{{ description }}</p> 
        </div>
        <div class="flex flex-col items-center mt-auto mb-4">
          <div class="flex items-center space-x-2 mb-2">
       <img :src='couple' class="mr-1 w-4 h-4" alt="couple" /> <span>{{ adult_count }}</span>
        <img :src='child' class="mr-1 w-4 h-4" alt="child" /> <span>{{ child_count }}</span>
        </div>
          <p class="text-md text-gray-700">Redemption Date & Time</p>
          <p class="text-md text-gray-500">{{ date_time }}</p>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import child from '../../assets/child.png'
import couple from '../../assets/couple.png'
  export default {
    name: 'VerifiedSuccess',
    props: {
      show: {
        type: Boolean,
        default: false
      },
      status: {
        type: String,
        default: ''
      },
      message: {
        type: String,
        default: ''
      },
      deal: {
        type: String,
        default: ''
      },
      order_id: {
        type: String,
        default: ''
      },
      date_time: {
        type: String,
        default: ''
      },
      description: { 
        type: String,
        default: ''
      },
      adult_count:{
        type: String,
        default:'',
      },
      child_count:{
        type: String,
        default:'',
      },

    },
    methods: {
      navigateBack() {
        this.$router.go(-1);
  },
},
data(){
  return{
    couple,
    child,
  }
},
    computed: {
      icon() {
        return require('@/assets/check.png'); 
    }
  },
};
  </script>
  