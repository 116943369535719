<!-- views/Profile.vue -->
<template>
    <div class="">
      <form class="max-w-sm mx-auto mt-28">
        <div>
          <label
            for="username"
            class="block mb-2 text-lg font-medium text-gray-900"
            >Username
          </label>
          <input
            type="text"
            name="username"
            id="username"
            class="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-md rounded-lg block w-full p-2.5"
            required
          />
        </div>
        <div>
          <label
            for="password"
            class="block mb-2 text-lg font-medium text-gray-900"
            >Password
          </label>
          <input
            type="password"
            name="password"
            id="username"
            class="shadow-sm bg-gray-50 font-medium border border-gray-300 text-gray-900 text-md rounded-lg block w-full p-2.5"
            required
          />
        </div>
        <div>
          <label
            for="password"
            class="block mb-2 text-lg font-medium text-gray-900"
            >Confirm Password</label
          >
          <input
            type="password"
            name="password"
            id="username"
            class="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-md rounded-lg block w-full p-2.5"
            required
          />
        </div>
        <div class="mt-10 justify-center flex">
          <button
            type="submit"
            class="rounded-lg px-3 bg-button py-2 text-lg font-normal shadow-lg text-white hover:bg-input focus:outline-none"
            @click="updateSupplierProfile()"
            >
            Reset Password
          </button>
        </div>
      </form>
      <!-- Popup message -->
      <div v-if="successAlert" class="absolute top-0 left-0 right-0 bg-green-500 text-white text-center py-2">
        Password updated successfully!
      </div>
    </div>
  </template>
  <script>
  
  import RepositoryFactory from '../repositories/RepositoryFactory';
  import AuthenticationHelper from '../helpers/AuthenticationHelper';
  
  const Suppliers = RepositoryFactory.get('supplier');
  export default {
    name:'ResetPassword',
    data() {
      return {
        profile: {},
        showPassword: false,
        successAlert: false,
        rules: {
          required: (value) => !!value || 'Required.',
          min: (v) => v.length >= 8 || 'Min 8 characters',
        },
      };
    },
    methods: {
      async getSupplierProfile() {
        const auth = AuthenticationHelper.getAuthUser(this.$store.state);
        const supplierID = AuthenticationHelper.getSupplierID(auth);
        const supplierType = AuthenticationHelper.getSupplierType(auth);
  
        const { data } = await Suppliers.getSupplierProfile(supplierID, supplierType);
        console.log(data);
        if (data.output !== null && data.statuscode !== 'E_FAIL') {
          [this.profile] = data.output;
        } else {
          console.error('Something went wrong');
        }
      },
  
      async updateSupplierProfile() {
        const auth = AuthenticationHelper.getAuthUser(this.$store.state);
        const supplierID = AuthenticationHelper.getSupplierID(auth);
        const supplierType = AuthenticationHelper.getSupplierType(auth);
  
        const { data } = await Suppliers.updateSupplierProfile(
          supplierID,
          supplierType,
          this.profile,
        );
        if (data.output !== null && data.statuscode !== 'E_FAIL') {
          this.successAlert = true;
          setTimeout(() => {
            this.successAlert = false;
          }, 5000);
        } else {
          console.error('Something went wrong');
        }
      },
  
    },
    computed: {},
    created() {
      if (!AuthenticationHelper.checkUserAuthentication(this.$store.state)) {
        this.$router.replace({ name: 'LoginScreen' });
      } else {
      
        this.getSupplierProfile();
      }
    },
  };
  </script>
  