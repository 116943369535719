<template>
    <div v-if="showModal" class="fixed inset-0 z-50 flex justify-center items-center bg-black bg-opacity-50">
        <div class="bg-white w-full h-full flex justify-center items-center">
          <div class="bg-white rounded shadow w-full h-screen mt-4">
            <div class="flex justify-between items-center mt-6 font-normal">
              <h2 class="text-2xl pl-4">Waiting For</h2>
              <button @click="closeModal" class="pr-12 text-gray-500 hover:text-pink-600 text-3xl">
                <i class="fas fa-times"></i>
              </button>
            </div>
            <hr class="my-4" />
            <div class="my-4 mx-auto max-w-screen-lg">
                <div class="flex justify-center  h-screen">
                    <span class="font-normal my-24 md:my-44 text-center">
                        <span class="text-5xl sm:text-6xl md:text-7xl lg:text-8xl xl:text-9xl">
                            {{ bookingService ? bookingService.name : '' }}
                        </span>
                    </span>
                </div>
                
          </div>
        </div>
      </div>
      </div>
        <div class="bg-back flex justify-between items-center p-3 text-white">
          <h2 class="m-0 text-sm font-semibold"> {{ bookingService ? bookingService.name : '' }}</h2>
          <i @click="openModal" class="fa-solid fa-id-card-clip text-xl cursor-pointer"></i>
        </div>
        
        <div class="p-3">
          <div class="mb-3">
            <div class="flex justify-between items-center mb-2">
              <p class="mb-0 text-xs">
            
              </p>
              <p class="mb-0 flex items-center text-xs">
                <img :src="couple" class="mr-1 w-4 h-4" alt="couple" />
                <span class="mr-2">{{ tripDetails.totalAdults || '' }}</span>
                <img :src="child" class="mr-1 w-4 h-4" alt="child" />
                <span>{{ tripDetails.totalKids || '' }}</span>
              </p>
            </div>
            <div class="relative">
              <div class="absolute left-[4.3px] top-0 bottom-0 w-0 h-[30px] border-l border-dotted border-success"></div>
              <p class="mb-2 relative text-xs leading-tight pl-5">
                <span class="absolute left-0 top-0 z-2 bg-white">
                  <i class="far fa-circle text-[0.6rem] text-success"></i>
                </span>
                <span>{{ tripDetails.pickUpLocation ||' N/A'}}  </span>
              </p>
              <p class="mb-1 relative text-xs leading-tight pl-5">
                <span class="absolute left-0 top-0 z-2 bg-white">
                  <i class="fas fa-map-marker-alt text-[0.7rem] text-success"></i>
                </span>
                <span class="whitespace-normal">{{ tripDetails.dropOffLocation || 'N/A' }}</span>
              </p>
            </div>
          </div>
          <div class="bg-gray-100 rounded p-2 my-2">
            <div class=" text-left font-semibold">
                <p class="mt-1 text-xs whitespace-nowrap">
                  <span class="font-bold text-black">Booking Status: </span> 
                  <span :class="[statusColorClass]"> {{ tripDetails.status ||'' }}</span>
                </p>
              </div>
              </div>
          <div class="bg-gray-100 rounded p-2 my-2">
            <div class="flex items-center">
              <div class="w-1/2 text-left">
                <p class="m-0 text-xs whitespace-nowrap">#{{ tripDetails.bookingNumber ||'' }}</p>
              </div>
              <div class="w-1/2 ml-2 text-right">
                <p class="text-right text-xs whitespace-nowrap">
                  <i class="fas fa-plane"></i>
                    {{ tripDetails.flightNumber || 'N/A'}}</p>
              </div>
            </div>
          </div>
          
          <div class="bg-gray-100 rounded p-2 my-2">
            <div class="flex justify-between">
              <div class="w-1/3 text-left">
                <p class="m-0 text-gray-500 text-[0.65rem]">Flight Status</p>
                <p class="m-0 text-xs">{{ tripDetails.flight_status || 'N/A' }}</p>
              </div>
              <div class="w-1/3 flex flex-col items-center justify-center">
                <p class="m-0 text-gray-500 text-center text-[0.65rem]">Scheduled Time</p>
                <p class="m-0 text-center text-xs">{{ tripDetails.scheduled_time || 'N/A' }}</p>
              </div>
              <div class="w-1/3 text-right">
                <p class="m-0 text-gray-500 text-[0.65rem]">Actual Time</p>
                <p class="m-0 text-xs">{{ tripDetails.actual_time || 'N/A' }}</p>
              </div>
            </div>
          </div>
        <!-- Remarks data feild-->
          <div class="bg-gray-100 rounded p-2 my-2">
            <div class="flex justify-between">
           
              <div class=" text-righ flex">
                <p class="m-0 text-gray-500 text-[0.65rem] pr-1`">Remarks:</p>
                <p class="m-0 text-xs pl-1">{{ remarksData || 'No remarks added yet!' }}</p>
              </div>
            </div>
          </div>
    
    </div>
  </template>
  
  <script>
  import child from '../assets/child.png'
  import couple from '../assets/couple.png'
  import Pusher from 'pusher-js';
  export default {
    name: "DetailCardComp",
  
    props: {
      driverDetails: Object,
      tripDetails: Object,
      bookingService: Object,
    },
    data(){
      return{
        remarksData: '',
        showModal:false,
        couple,
        child,
      }
    },
    mounted(){
      this.updateRemarks(this.bookingService.remarks)
    },
    computed: {
      statusColorClass() {
        switch (this.tripDetails.status) {
          case 'NEW':
            return 'text-indigo-500';
          case 'COMPLETED':
            return 'text-button';
          case 'REJECTED':
            return 'text-rose-600';
          case 'COMPLETED*':
            return 'text-rose-600';
          default:
            return 'text-orange-500';
        }
      },
    },
    created() {
      this.initializePusher();
    },
      methods: {
      openModal() {
        this.showModal = true;
      },
      closeModal() {
        this.showModal = false;
      },
    initializePusher() {
      const pusher = new Pusher(process.env.VUE_APP_PUSHER_KEY, {
        cluster: process.env.VUE_APP_PUSHER_CLUSTER,
        encrypted: true,
      });
  
      pusher.connection.bind('connected', () => {
        console.log('Pusher connected successfully');
      });
  
      pusher.connection.bind('error', (err) => {
        console.error('Pusher connection error:', err);
      });
  
      const channel = pusher.subscribe(process.env.VUE_APP_PUSHER_CHANNEL);
  channel.bind_global((eventName, data) => {
    if (data && data.booking_id) {
      try {
        let bookingIdObject = JSON.parse(data.booking_id);
        let bookingId = bookingIdObject.booking_id;
  
        // Accessing the remarks from the booking_id object
        let remarksObject = JSON.parse(data.booking_id);
        let remarks = remarksObject.remarks;
  
        // Accessing the ID from the route parameters
        let orderNumber = this.$route.params.code;
        if (orderNumber === bookingId) {
        
            this.remarksData = remarks
          
          console.log(`Received event '${eventName}' with data:`, data);
        } else {
          console.log(`Received event '${eventName}' but booking_id did not match`);
        }
      } catch (error) {
        console.error("Error parsing booking_id:", error);
      }
    } else {
      console.log(`Received event '${eventName}' with invalid or missing booking_id`);
    }
  });
  
  
  
    },
    updateRemarks(val){
      console.log(val);
  if(val){
    this.remarksData =val
  }
  else{
    console.log(val , "not available");
  }
    }
  },
  };
  </script>
  