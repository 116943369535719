<template>
  <div class="relative bg-white rounded-lg p-4">
    <div class="font-sans text-center absolute z-10 right-[-2px] top-[-4px] bg-pink-700 mb-1 size-6 text-white rounded-md font-semibold cursor-pointer" @click="cancelImage">
      x
    </div>
    <h2 class="text-xl font-normal mb-4 flex justify-center">Take Snapshot</h2>
    <!-- Camera Stream -->
    <div class="camera-modal px-6">
      <video ref="video" class="camera-stream" autoplay></video>
    </div>

    <!-- Buttons -->
    <div class="flex justify-center mt-4">
      <button v-if="mediaStream" @click="capture" class="mx-1 px-2 py-1 text-md font-normal bg-button hover:bg-input text-white rounded-lg">
        Capture
      </button>
      <button @click="selectFromGallery" class="mx-1 px-2 py-1 text-md font-normal bg-button hover:bg-input text-white rounded-lg">
        Select
      </button>
      <button v-if="takenImages.length > 0" @click="saveStatus(status)" class="mx-1 px-2 py-1 text-md font-normal bg-button hover:bg-input text-white rounded-lg">
        Save
      </button>
    </div>

    <!-- Captured Images -->
    <div v-if="takenImages.length > 0" class="flex flex-wrap justify-center mt-3">
      <div v-for="(takenImage, index) in takenImages" :key="index" class="mx-2 text-center">
        <span class="block mb-1">{{ index + 1 }}</span>
        <img :src="takenImage" alt="Taken Image" class="w-28 h-24 rounded-lg" @click="retakeImage(index)" />
      </div>
    </div>

    <!-- Save Message -->
    <div v-if="showSaveMsg" class="error-popup text-md">
      Image saving ...
    </div>

    <!-- File Input Gallery-->
    <input type="file" ref="fileInput" accept="image/*" @change="handleFileChange" class="hidden" multiple />

    <!-- Error Message -->
    <div v-if="errorMessage" class="text-red-500 text-center mt-4">
      {{ errorMessage }}
    </div>
  </div>
</template>

<script>
import RepositoryFactory from "../../repositories/RepositoryFactory";
const Suppliers = RepositoryFactory.get("supplier");

export default {
  name: "NoShowDialog",
  props: {
    status: {
      type: String,
      required: true,
    },
    bookingId: {
      type: String,
      required: true,
    },
    supplierId: {
      type: String,
      required: true,
    },
    driverId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      mediaStream: null,
      latitude: "",
      longitude: "",
      showSaveMsg: false,
      takenImages: [],
      showImageOptionsPopup: false,
      errorMessage: "",
      bookingID: this.bookingId,
      supplierID: this.supplierId,
      driverID: this.driverId,
    };
  },
  methods: {
    initializeCamera() {
      const constraints = {
        video: {
          facingMode: { ideal: "environment" },
        },
      };
      navigator.mediaDevices
        .getUserMedia(constraints)
        .then((mediaStream) => {
          this.mediaStream = mediaStream;
          this.$refs.video.srcObject = mediaStream;
        })
        .catch((error) => {
          console.error("Error accessing media devices:", error);
          navigator.mediaDevices
            .getUserMedia({ video: true })
            .then((mediaStream) => {
              this.mediaStream = mediaStream;
              this.$refs.video.srcObject = mediaStream;
            })
            .catch((defaultError) =>
              console.error(
                "Error accessing media devices with default constraints:",
                defaultError
              )
            );
        });
    },
    resetCamera() {
      if (this.mediaStream) {
        this.mediaStream.getTracks().forEach((track) => {
          track.stop();
        });
        this.mediaStream = null;
      }
    },
    capture() {
      if (this.takenImages.length >= 4) {
        this.showErrorMessage("You can't select more than 4 images.");
        return;
      }
      const canvas = document.createElement("canvas");
      const video = this.$refs.video;
      const context = canvas.getContext("2d");

      // Set canvas
      canvas.width = video.videoWidth;
      canvas.height = video.videoHeight;

      // video frame
      context.drawImage(video, 0, 0, canvas.width, canvas.height);

      const timestamp = new Date().toLocaleString();
      context.font = "16px Arial";
      context.fillStyle = "white";
      context.textAlign = "left";
      context.fillText(timestamp, 10, canvas.height - 10);

      const status = this.status;
      context.fillText(status, 10, canvas.height - 30);

      const base64Image = canvas.toDataURL("image/png");
      this.addTakenImage(base64Image);
    },
    resetImage() {
      this.resetCamera();
      this.initializeCamera();
    },
    cancelImage() {
      this.resetImage();
      this.stopCamera();
      window.location.reload();
    },
    async saveStatus(currentStatus) {
      this.loading = true;
      this.showSaveMsg = true;
      await this.$nextTick();
      console.log("Taken Images:", this.takenImages);
      const base64Images = this.takenImages;
      const bookingServiceId =
        this.bookingDetails?.booking_services?.[0]?.id || "";
      let updatedStatus = "";
      //current status
      if (currentStatus === "pick up") {
        updatedStatus = "PAX ON BOARD";
      } else if (currentStatus === "on the way") {
        updatedStatus = "ON SITE";
      } else {
        updatedStatus = "COMPLETED*";
      }
      console.log("Current Status:", currentStatus);
      console.log("Updated Status:", updatedStatus);
      try {
        await Suppliers.updateSupplierBookingServiceNoShow(
          this.bookingId,
          this.supplierId,
          this.driverId,
          bookingServiceId,
          updatedStatus,
          base64Images,
          this.latitude,
          this.longitude
        );

        await this.saveBookingStatus(updatedStatus);

        this.resetImage();
        this.stopCamera();
        window.location.reload();
      } catch (error) {
        console.error(`Error in saving status (${updatedStatus}):`, error);
        this.loading = false;
      }
    },
    stopCamera() {
      if (this.mediaStream) {
        const tracks = this.mediaStream.getTracks();
        tracks.forEach((track) => {
          track.stop();
        });
        this.mediaStream = null;
        const videoElement = this.$refs.video;
        if (videoElement) {
          videoElement.srcObject = null;
        }
      }
    },
    beforeDestroy() {
      this.stopCamera();
    },
    async getLocation() {
      try {
        const location = await navigator.permissions.query({
          name: "geolocation",
        });
        if (location.state === "denied") {
          console.log(
            "Geolocation permission has been blocked. Please reset it in your browser settings to enable location services."
          );
          return null;
        }
        if (location.state === "granted" || location.state === "prompt") {
          return new Promise((resolve, reject) => {
            navigator.geolocation.getCurrentPosition(
              (pos) => resolve(pos),
              (err) => reject(err)
            );
          });
        }
      } catch (error) {
        console.error("Error accessing geolocation:", error);
        return null;
      }
    },
    async locateMe() {
      try {
        const location = await this.getLocation();
        this.latitude = location.coords.latitude;
        this.longitude = location.coords.longitude;
      } catch (e) {
        this.latitude = "";
        this.longitude = "";
      }
    },
    addTakenImage(imageSrc) {
      if (this.takenImages.length < 4) {
        this.takenImages.push(imageSrc);
      } else {
        this.showErrorMessage("You can't select more than 4 images.");
      }
    },
    retakeImage(index) {
      this.takenImages.splice(index, 1);
    },
    async getBookingDetails() {
      try {
        const response = await Suppliers.getSupplierBookingDetails(
          this.bookingID,
          this.supplierID
        );
        this.bookingDetails = response.data;
      } catch (error) {
        console.error("Error fetching booking details:", error);
      }
    },
    async saveBookingStatus(status) {
      this.loading = true;
      try {
        await Suppliers.updateSupplierBookingStatus(
          this.bookingID,
          this.supplierID,
          this.driverID,
          status,
          this.latitude,
          this.longitude
        );

        await this.getBookingDetails();
      } catch (error) {
        console.error("Error updating booking status:", error);
      }
      this.loading = false;
    },
    showImageOptions() {
      this.showImageOptionsPopup = true;
    },
    closeImageOptions() {
      this.showImageOptionsPopup = false;
    },
    selectFromGallery() {
      this.closeImageOptions();
      this.$refs.fileInput.click();
    },
    handleFileChange(event) {
      const files = event.target.files;
      if (files.length > 0) {
        for (let i = 0; i < files.length; i++) {
          if (this.takenImages.length < 4) {
            const file = files[i];
            const reader = new FileReader();
            reader.onload = (e) => {
              const imageSrc = e.target.result;
              this.addTimestampToImage(imageSrc);
            };
            reader.readAsDataURL(file);
          } else {
            this.showErrorMessage("You can't select more than 4 images.");
            break;
          }
        }
      }
    },
    addTimestampToImage(imageSrc) {
      const img = new Image();
      img.src = imageSrc;
      img.onload = () => {
        const canvas = document.createElement("canvas");
        const context = canvas.getContext("2d");

        canvas.width = img.width;
        canvas.height = img.height;

        context.drawImage(img, 0, 0);

        const timestamp = new Date().toLocaleString();
        context.font = "16px Arial";
        context.fillStyle = "white";
        context.textAlign = "left";
        context.fillText(timestamp, 10, canvas.height - 10);

        const status = this.status;
        context.fillText(status, 10, canvas.height - 30);

        const base64Image = canvas.toDataURL("image/png");
        this.addTakenImage(base64Image);
      };
    },
    showErrorMessage(message) {
      this.errorMessage = message;
      setTimeout(() => {
        this.errorMessage = "";
      }, 4000);
    },
  },
  async created() {
    this.initializeCamera();
    await this.locateMe();
    await this.getBookingDetails();
  },
};
</script>

<style scoped>
.camera-stream {
  width: 100%;
  max-width: 300px;
  height: auto;
}
</style>