<template>
  <div>
    <VueDatePicker v-model="date" inline auto-apply model-type="yyyy-MM-dd" :format="dateFormat" :markers="customMarkers" now-button-label="Current" :enable-time-picker="false"></VueDatePicker>
  </div>
</template>

<script>
/* eslint-disable */
import VueDatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css';
import RepositoryFactory from '../repositories/RepositoryFactory';

const Suppliers = RepositoryFactory.get("supplier");

export default {
  components: { VueDatePicker },
  props: {
    shouldFetchBookings: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      date: '',
      dateFormat: 'yyyy-MM-dd',
      supplierID: "",
      supplierType: "",
      customMarkers: [],
      class: "custom-datepicker"
    };
  },
  mounted() {
    this.supplierID = this.$store.state.authentication.supplierID;
    this.supplierType = this.$store.state.authentication.supplierType;
    if (this.shouldFetchBookings) {
      this.getAllBookings();
    }
  },
  watch: {
    date(newDate) {
      this.$emit('calendarDateSelected', newDate);
    },
  },
  methods: {
    async getAllBookings() {
      try {
        const { data } = await Suppliers.getSupplierBookings(this.supplierID, this.supplierType, '');
        if (data.statuscode === process.env.VUE_APP_API_STATUS_SUCCESS) {
          const bookingsData = data.output;
          for (const bookingData of bookingsData) {
            const date = bookingData.date;
            const color = this.getColorForBookings(bookingData.booking);
            const obj = {
              color: color,
              date: date,
            };
            this.customMarkers.push(obj);
          }
        }
      } catch (error) {
        console.error('Error fetching bookings:', error);
      }
    },
    getColorForBookings(bookings) {
      const completedStatuses = ["COMPLETED", "", "COMPLETED*", "REJECTED", "NOT APPEARED"];
      const today = new Date().toISOString().split('T')[0]; 
      let color = "orange";
  
      const allCompleted = bookings.every(booking => completedStatuses.includes(booking.booking_status.toUpperCase()));
  
      if (allCompleted) {
        return "green"; 
      }
  
      for (const booking of bookings) {
        const bookingDate = booking.booking_date.split('T')[0]; 
  
        if (bookingDate === !today && !completedStatuses.includes(booking.booking_status.toUpperCase())) {
          color = "orange";
          break;
        }
  
        if (bookingDate >= today) {
          color = "blue";
          break;
        }
      }
  
      return color;
    }
  }
}
</script>

<style scoped>
.dp__theme_light {
  --dp-cell-size: 41px; 
  --dp-cell-padding: 2px; 
  --dp-font-size: 17px; 
  --dp-text-color: #4d4545;
  --dp-icon-color: #1fb845;
}
</style>
