<template>
  <section>
    <div v-if="loading" class="flex justify-center items-center lg:mt-56 mt-72">
      <LoadingAnimator />
    </div>
    <div v-else class="w-auto">
    
      <TripDetail
        :driverDetails="driverDetails"
        :bookingService="
          bookingDetails.booking_services
            ? bookingDetails.booking_services[0]
            : []
        "
        :tripDetails="tripDetails"
      />
      <div class="flex justify-center">
        <div v-if="bookingDetails.booking_status.toLowerCase() === 'completed'">
          <span class="text-green-600 text-lg font-normal mt-5"
            >Successfully Completed</span
          >
        </div>
        <div v-else>
          <div
            v-if="
              showStatusButton &&
              bookingDetails.booking_status.toLowerCase() !== 'rejected' &&
              bookingDetails.booking_status.toLowerCase() !== 'completed*'
            "
          >
          <button
           class="px-6 bg-button py-2 md:text-lg text-md font-normal rounded-lg mt-5 mr-1 text-white hover:bg-input focus:outline-none"
          @click="statusBooking()">
          {{ showStatus }}
          </button>
          </div>
        </div>
      </div>
      <div class="flex justify-center" v-if="!loading">
      
        <div v-if="  bookingDetails.booking_status.toLowerCase() === 'on site' &&
            (bookingDetails.booking_services[0].status === '' ||
              bookingDetails.booking_services[0].status === null)
          "
        >
          <button  @click=" handleSwipeComplete('pick up')" class="px-6 bg-button py-2 md:text-lg text-md font-normal rounded-lg mt-5 mr-1 text-white hover:bg-input focus:outline-none"> 
            Pick Up
          </button>
        </div>
        
        <div class="flex justify-center "
        v-if="
          showNoShowButton &&
          bookingDetails.booking_status.toLowerCase() === 'on site'
        "
      >
        <button
         
          @click="handleSwipeComplete('no show')"
          class="px-3 py-2 md:text-lg text-md font-normal bg-pink-700 hover:bg-input text-white rounded-lg mt-5 focus:outline-none"
        >
          No Show
        </button>
      </div>
        <div
          v-if="
            showNoShowButton &&
            bookingDetails.booking_status.toLowerCase() === 'on site'
          "
        >
          <button

             @click="handleSwipeComplete()"
          />
        </div>
      </div>
      <div
        v-if="showNoShowDialog"
        class="fixed top-0 left-0 w-full h-full bg-gray-500 bg-opacity-90 flex justify-center items-center"
      >
        <NoShowDialog
          :status="currentStatus"
          :booking-id="bookingID"
          :supplier-id="supplierID"
          :driver-id="driverID"
        />
      </div>
      <div class="flex justify-center mt-1.5" v-if="!loading">
        <button
          v-if="
            bookingDetails.booking_status.toLowerCase() === 'on site' &&
            (bookingDetails.booking_services[0].status === '' ||
              bookingDetails.booking_services[0].status === null)
          "
          @click="handleShowQRCode()"
          class="px-[44px] bg-gray-500 py-2 md:text-xl text-md rounded-lg font-normal text-white hover:bg-input focus:outline-none"
        >
          Show QR Code
        </button>
        <div>
          
        </div>
      </div>
      <div
        v-if="showQRModal"
        class="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50"
      >
        <div class="bg-white rounded-lg shadow-lg p-6">
          <div class="flex flex-col items-center">
            <div class="text-center font-normal">
              {{
                bookingDetails.booking_services
                  ? bookingDetails.booking_services[0].qr_code
                  : ""
              }}
            </div>
            <div class="mt-4 text-center">
              <img
                :src="`${bookingDetails.booking_services ? qrCodeSrc : ''}`"
                alt="QR Code"
                class="w-64"
              />
            </div>
            <div class="mt-4 text-center text-button font-normal">
              Scan QR Code (For Customer)
            </div>
          </div>
          <div class="mt-6 flex justify-center">
            <button
              @click="showQRModal = false"
              class="px-4 py-2 text-lg font-normal bg-pink-700 hover:bg-input text-white rounded-lg m-2 focus:outline-none"
            >
              Close
            </button>
          </div>
        </div>
      </div>
      <div class="flex justify-center mb-1">
        <div
          v-if="
            bookingDetails.booking_status.toLowerCase() === 'new' &&
            supplierAcceptence === 'Yes'"
        >
          <button
           class="px-8 bg-pink-700 py-2 md:text-lg text-md font-normal rounded-lg  mr-1 text-white hover:bg-input focus:outline-none"
            @click="saveBookingStatus('REJECTED')" 
          >Rejected</button>
      </div>
      </div>
      <div v-if="showSaveMsg" class="error-popup text-md">
        Image saving ...
      </div>
    </div>
  </section>
</template>

<script>
import TripDetail from "@/components/driver/TripDetail";
import RepositoryFactory from "../repositories/RepositoryFactory";
import AuthenticationHelper from "../helpers/AuthenticationHelper";
import LoadingAnimator from "@/components/LoadingAnimator";
import NoShowDialog from "@/components/driver/NoShowDialog.vue";
const Suppliers = RepositoryFactory.get("supplier");
export default {
  components: { NoShowDialog, TripDetail, LoadingAnimator},
  data() {
    return {
      supplier_type: this.$store.state.authentication.supplierType,
      mediaStream: null,
      imageCaptured: false,
      imageSource: "",
      blob: null,
      showNoShowDialog: false,
      showNoShowButton: true,
      showRetakeOption: false,
      driverDetails: {},
      tripDetails: [],
      bookingDetails: {},
      loading: true,
      latitude: "",
      longitude: "",
      supplierID: "",
      bookingID: this.$route.params.code,
      pickupFlag: false,
      driverID: "",
      showQRModal: false,
      showSaveMsg: false,
      startX: 0,
      endX: 0,
      currentStatus: this.status,
    };
  },
  async created() {
    await this.checkLoggedIn();
    await this.getBookingDetails();
    await this.locateMe();
    await this.checkPickup();
    this.loading = false;
    console.log("Supplier Acceptance:", this.supplierAcceptance);
  },
  computed: {
    supplierAcceptence() {
      return this.$store.state.authentication.supplierAcceptance;
    },
    showStatusButton() {
      if (
        (this.bookingDetails.booking_status.toLowerCase() === "on site" &&
          !this.pickupFlag) ||
        this.bookingDetails.booking_status.toLowerCase() === "completed"||
       this.bookingDetails.booking_status.toLowerCase() === 'completed*'
      ) {
        return false;
      }
      return true;
    },
    qrCodeSrc() {
      return this.bookingDetails.booking_services[0].qr_code_image;
    },
    showStatus() {
      if (this.supplierAcceptance === 'Yes') {
        if (this.bookingDetails.booking_status.toLowerCase() === "new") {
        return "Accept Job";
      }
      if (this.bookingDetails.booking_status.toLowerCase() === "confirmed") {
        return "On the Way";
      }
      if (
        this.bookingDetails.booking_status.toLowerCase() === "On the Way"
      ) {
        return "On Site";
      }
      if (
        this.bookingDetails.booking_status.toLowerCase() ===
          "on site" &&
        this.pickupFlag
      ) {
        return "Ride Complete";
      }
      if (
        this.bookingDetails.booking_status.toLowerCase() === "pax on board"
      ) {
        return "Ride Complete";
      }
      return "";
    }
    else{
      if (this.bookingDetails.booking_status.toLowerCase() === "new") {
        return "Confirmed";
      }
      if (this.bookingDetails.booking_status.toLowerCase() === "confirmed") {
        return "On the Way";
      }
      if (
        this.bookingDetails.booking_status.toLowerCase() === "on the way"
      ) {
        return "On Site";
      }
      if (
        this.bookingDetails.booking_status.toLowerCase() ===
          "on site" &&
        this.pickupFlag
      ) {
        return "Ride Complete";
      }
      if (
        this.bookingDetails.booking_status.toLowerCase() === "pax on board"
      ) {
        return "Ride Complete";
      }
      return "";
    }
  },
    setNewStatus() {
  if (this.driverDetails.acceptance === 'Yes') {
    if (this.bookingDetails.booking_status.toLowerCase() === "new") {
        return "CONFIRMED";
      }
      if (this.bookingDetails.booking_status.toLowerCase() === "confirmed") {
        return "ON THE WAY";
      }
      if (
        this.bookingDetails.booking_status.toLowerCase() === "on the way"
      ) {
        return "ON SITE";
      }
      if (
        this.bookingDetails.booking_status.toLowerCase() ===
          "on site" &&
        !this.pickupFlag
      ) {
        return "PAX ON BOARD";
      }
      if (
        this.bookingDetails.booking_status.toLowerCase() ===
          "on site" &&
        this.pickupFlag
      ) {
        return "COMPLETED";
      }
      if (
        this.bookingDetails.booking_status.toLowerCase() === "pax on board"
      ) {
        return "COMPLETED";
      }
      return "";
  } else {
    if (this.bookingDetails.booking_status.toLowerCase() === "new") {
        return "CONFIRMED";
      }
      if (this.bookingDetails.booking_status.toLowerCase() === "confirmed") {
        return "ON THE WAY";
      }
      if (
        this.bookingDetails.booking_status.toLowerCase() === "on the way"
      ) {
        return "ON SITE";
      }
      if (
        this.bookingDetails.booking_status.toLowerCase() ===
          "on site" &&
        !this.pickupFlag
      ) {
        return "PAX ON BOARD";
      }
      if (
        this.bookingDetails.booking_status.toLowerCase() ===
          "on site" &&
        this.pickupFlag
      ) {
        return "COMPLETED";
      }
      if (
        this.bookingDetails.booking_status.toLowerCase() === "pax on board"
      ) {
        return "COMPLETED";
      }
      return "";
    }
}
  },
  methods: {
    statusBooking(){
     if (this.bookingDetails.booking_status.toLowerCase() === 'on the way')
      {
      this.handleSwipeComplete('on the way');
    } else {
      this.saveBookingStatus(this.setNewStatus);
    }
  },
  handleSwipeComplete(status) {
  this.currentStatus= status
        this.showNoShowDialog = true;     
  },

  
    handleSwipe(event) {
      console.log("Swipe direction:", event.direction);
    },

    handleNotShownClick() {
      this.showNoShowDialog = true;
    },
    handleShowQRCode() {
      this.showQRModal = true;
    },
    async checkPickup() {
      let pickupFlag = true;
      this.bookingDetails.booking_services.forEach((service) => {
        if (service.status === "" || service.status === null) {
          pickupFlag = false;
        }
      });
      this.pickupFlag = pickupFlag;
    },
    async getLocation() {
      try {
        const location = await navigator.permissions.query({
          name: "geolocation",
        });
        if (location.state === "denied") {
          console.log(
            "Geolocation permission has been blocked. Please reset it in your browser settings to enable location services."
          );
          return null;
        }

        if (location.state === "granted" || location.state === "prompt") {
          return new Promise((resolve, reject) => {
            navigator.geolocation.getCurrentPosition(
              (pos) => resolve(pos),
              (err) => reject(err)
            );
          });
        }
      } catch (error) {
        console.error("Error accessing geolocation:", error);
        return null;
      }
    },
    async locateMe() {
      try {
        const location = await this.getLocation();
        this.latitude = location.coords.latitude;
        this.longitude = location.coords.longitude;
      } catch (e) {
        this.latitude = "";
        this.longitude = "";
      }
    },
    setBookingStatus(value) {
      this.newStatus = value;
    },
    async saveBookingStatus(status) {
      this.loading = true;

      await Suppliers.updateSupplierBookingStatus(
        this.bookingID,
        this.supplierID,
        this.driverID,
        status,
        this.latitude,
        this.longitude
      );

      await this.getBookingDetails();

      this.loading = false;
    },

    async getBookingDetails() {
      const bookingID = this.$route.params.code;
      const { data } = await Suppliers.getDriverBookingDetails(
        bookingID,
        this.driverID,
        this.supplier_type
      );

      if (data.statuscode === process.env.VUE_APP_API_STATUS_SUCCESS) {
        const bookingData = data.output;
        this.bookingDetails = bookingData;
        this.tripDetails = {
          bookingNumber: bookingData.booking_number,
          bookingDate: bookingData.booking_date,
          pickUpTime: bookingData.booking_time,
          totalGuests: bookingData.total_adult,
          status: bookingData.booking_status,
          pickUpLocation: bookingData.pickup_point,
          dropOffLocation: bookingData.dropoff_point,
          flightTime: bookingData.supplier_time,
          flightNumber: bookingData.flight_number,
          customerName: bookingData.name,
          phoneNumber: bookingData.flight_number,
          scheduled_time: bookingData.flight_scheduled_time,
          actual_time: bookingData.flight_actual_time,
          flight_status: bookingData.flight_status,
          transfer_type: bookingData.transfer_type,
        };
        this.driverDetails = {
          name: bookingData.name,
          image: bookingData.driver_image,
          vehicle: bookingData.vehicle_number,
          contact: bookingData.contact_number,
        };
      }
    },

    async checkLoggedIn() {
      if (!AuthenticationHelper.checkUserAuthentication(this.$store.state)) {
        this.$router.replace({ name: "LoginScreen" });
      } else {
        const auth = AuthenticationHelper.getAuthUser(this.$store.state);
        this.driverID = AuthenticationHelper.getSupplierID(auth);
      }
    },
  },
};
</script>

<style>
.no-show-dialog {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #fff;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
}
.camera-stream {
  width: 100%;
  max-width: 300px;
  height: auto;
  border-radius: 10px;
}
.error-popup {
  position: fixed;
  top: 20px;
  left: 50%;
  color: white;
  transform: translateX(-50%);
  background-color: #a0a0a0;
  padding: 10px;
  border-radius: 15px;
  box-shadow: 0 2px 4px rgba(219, 224, 220, 0.1);
  z-index: 9999;
}
</style> 




 





<!-- 


<template>
  <section>
    <div v-if="loading" class="flex justify-center items-center lg:mt-56 mt-72">
      <LoadingAnimator />
    </div>
    <div v-else class="w-auto">
      <TripDetail
        :driverDetails="driverDetails"
        :bookingService="
          bookingDetails.booking_services
            ? bookingDetails.booking_services[0]
            : []
        "
        :tripDetails="tripDetails"
      />
      <div class="flex justify-center">
        <div v-if="bookingDetails.booking_status && bookingDetails.booking_status.toLowerCase() === 'completed'">
          <span class="text-green-600 text-lg font-normal mt-5">
            Successfully Completed
          </span>
        </div>
        <div v-else>
          <button
            v-if="showStatusButton && bookingDetails.booking_status && bookingDetails.booking_status.toLowerCase() !== 'rejected' && bookingDetails.booking_status.toLowerCase() !== 'completed*'"
            @click="statusBooking()"
            class="px-4 py-2 md:text-xl text-md font-normal bg-button hover:bg-input text-white rounded-lg focus:outline-none mt-5"
          >
            {{ showStatus }}
          </button>
        </div>
      </div>
    </div>
    <div class="flex justify-center" v-if="!loading">
      <div v-if="bookingDetails.booking_status && bookingDetails.booking_status.toLowerCase() === 'on site' && (!bookingDetails.booking_services[0].status || bookingDetails.booking_services[0].status === null)">
        <button @click="handleSwipeComplete('pick up')" class="px-6 bg-button py-2 md:text-lg text-md font-normal rounded-lg mt-5 mr-1 text-white hover:bg-input focus:outline-none">
          Pick Up
        </button>
      </div>
      <div class="flex justify-center" v-if="showNoShowButton && bookingDetails.booking_status && bookingDetails.booking_status.toLowerCase() === 'on site'">
        <button @click="handleSwipeComplete('no show')" class="px-3 py-2 md:text-lg text-md font-normal bg-pink-700 hover:bg-input text-white rounded-lg mt-5 focus:outline-none">
          No Show
        </button>
      </div>
      <div v-if="showNoShowButton && bookingDetails.booking_status && bookingDetails.booking_status.toLowerCase() === 'on site'">
        <button @click="handleSwipeComplete()" />
      </div>
    </div>
    <div v-if="showNoShowDialog" class="fixed top-0 left-0 w-full h-full bg-gray-500 bg-opacity-90 flex justify-center items-center">
      <NoShowDialog
        :status="currentStatus"
        :booking-id="bookingID"
        :supplier-id="supplierID"
        :driver-id="driverID"
      />
    </div>
    <div class="flex justify-center mt-1.5" v-if="!loading">
      <button
        v-if="bookingDetails.booking_status && bookingDetails.booking_status.toLowerCase() === 'on site' && (!bookingDetails.booking_services[0].status || bookingDetails.booking_services[0].status === null)"
        @click="handleShowQRCode()"
        class="px-[44px] bg-gray-500 py-2 md:text-xl text-md font-normal rounded-lg font-normal text-white hover:bg-input focus:outline-none"
      >
        Show QR Code
      </button>
    </div>
    <div v-if="showQRModal" class="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
      <div class="bg-white rounded-lg shadow-lg p-6">
        <div class="flex flex-col items-center">
          <div class="text-center font-normal">
            {{ bookingDetails.booking_services ? bookingDetails.booking_services[0].qr_code : "" }}
          </div>
          <div class="mt-4 text-center">
            <img :src="`${bookingDetails.booking_services ? qrCodeSrc : ''}`" alt="QR Code" class="w-64" />
          </div>
          <div class="mt-4 text-center text-button font-normal">
            Scan QR Code (For Customer)
          </div>
        </div>
        <div class="mt-6 flex justify-center">
          <button @click="showQRModal = false" class="px-4 py-2 text-lg font-normal bg-pink-700 hover:bg-input text-white rounded-lg m-2 focus:outline-none">
            Close
          </button>
        </div>
      </div>
    </div>
    <div class="flex justify-center mb-1">
      <div v-if="bookingDetails.booking_status && bookingDetails.booking_status.toLowerCase() === 'new' && supplierAcceptence === 'Yes'">
        <button @click="saveBookingStatus('REJECTED')" />
      </div>
      <div v-if="showSaveMsg" class="error-popup text-md">
        Image saved successfully
      </div>
    </div>
  </section>
</template>
<script>
import TripDetail from "@/components/TripDetail";
import RepositoryFactory from "../repositories/RepositoryFactory";
import AuthenticationHelper from "../helpers/AuthenticationHelper";
import LoadingAnimator from "@/components/LoadingAnimator";
import NoShowDialog from "@/components/NoShowDialog.vue";

const Suppliers = RepositoryFactory.get("supplier");

export default {
  components: { NoShowDialog, TripDetail, LoadingAnimator },
  data() {
    return {
      supplier_type: this.$store.state.authentication.supplierType,
      mediaStream: null,
      imageCaptured: false,
      imageSource: "",
      blob: null,
      showNoShowDialog: false,
      showNoShowButton: true,
      showRetakeOption: false,
      driverDetails: {},
      tripDetails: [],
      bookingDetails: {},
      loading: true,
      latitude: "",
      longitude: "",
      supplierID: "",
      bookingID: this.$route.params.code,
      pickupFlag: false,
      driverID: "",
      showQRModal: false,
      showSaveMsg: false,
      startX: 0,
      endX: 0,
      currentStatus: this.status,
    };
  },
  async created() {
    await this.checkLoggedIn();
    await this.getBookingDetails();
    await this.locateMe();
    await this.checkPickup();
    this.loading = false;
    console.log("Supplier Acceptance:", this.supplierAcceptance);
  },
  computed: {
    supplierAcceptence() {
      return this.$store.state.authentication.supplierAcceptance;
    },
    showStatusButton() {
      if (
        (this.bookingDetails.booking_status &&
          this.bookingDetails.booking_status.toLowerCase() === "on site" &&
          !this.pickupFlag) ||
        this.bookingDetails.booking_status &&
          this.bookingDetails.booking_status.toLowerCase() === "completed" ||
        this.bookingDetails.booking_status &&
          this.bookingDetails.booking_status.toLowerCase() === "completed*"
      ) {
        return false;
      }
      return true;
    },
    qrCodeSrc() {
      return this.bookingDetails.booking_services[0].qr_code_image;
    },
    showStatus() {
      if (this.supplierAcceptance === "Yes") {
        if (
          this.bookingDetails.booking_status &&
          this.bookingDetails.booking_status.toLowerCase() === "new"
        ) {
          return "Accept Job";
        }
        if (
          this.bookingDetails.booking_status &&
          this.bookingDetails.booking_status.toLowerCase() === "confirmed"
        ) {
          return "On the Way";
        }
        if (
          this.bookingDetails.booking_status &&
          this.bookingDetails.booking_status.toLowerCase() === "on the way"
        ) {
          return "On Site";
        }
        if (
          this.bookingDetails.booking_status &&
          this.bookingDetails.booking_status.toLowerCase() === "on site" &&
          this.pickupFlag
        ) {
          return "Ride Complete";
        }
        if (
          this.bookingDetails.booking_status &&
          this.bookingDetails.booking_status.toLowerCase() === "pax on board"
        ) {
          return "Ride Complete";
        }
        return "";
      } else {
        if (
          this.bookingDetails.booking_status &&
          this.bookingDetails.booking_status.toLowerCase() === "new"
        ) {
          return "Confirmed";
        }
        if (
          this.bookingDetails.booking_status &&
          this.bookingDetails.booking_status.toLowerCase() === "confirmed"
        ) {
          return "On the Way";
        }
        if (
          this.bookingDetails.booking_status &&
          this.bookingDetails.booking_status.toLowerCase() === "on the way"
        ) {
          return "On Site";
        }
        if (
          this.bookingDetails.booking_status &&
          this.bookingDetails.booking_status.toLowerCase() === "on site" &&
          this.pickupFlag
        ) {
          return "Ride Complete";
        }
        if (
          this.bookingDetails.booking_status &&
          this.bookingDetails.booking_status.toLowerCase() === "pax on board"
        ) {
          return "Ride Complete";
        }
        return "";
      }
    },
    setNewStatus() {
      if (this.driverDetails.acceptance === "Yes") {
        if (
          this.bookingDetails.booking_status &&
          this.bookingDetails.booking_status.toLowerCase() === "new"
        ) {
          return "ACCEPTED";
        }
        if (
          this.bookingDetails.booking_status &&
          this.bookingDetails.booking_status.toLowerCase() === "confirmed"
        ) {
          return "ON THE WAY";
        }
        if (
          this.bookingDetails.booking_status &&
          this.bookingDetails.booking_status.toLowerCase() === "on the way"
        ) {
          return "ON SITE";
        }
        if (
          this.bookingDetails.booking_status &&
          this.bookingDetails.booking_status.toLowerCase() === "on site" &&
          this.pickupFlag
        ) {
          return "PAX ON BOARD";
        }
        if (
          this.bookingDetails.booking_status &&
          this.bookingDetails.booking_status.toLowerCase() === "pax on board"
        ) {
          return "COMPLETED";
        }
        return "";
      } else {
        if (
          this.bookingDetails.booking_status &&
          this.bookingDetails.booking_status.toLowerCase() === "new"
        ) {
          return "CONFIRMED";
        }
        if (
          this.bookingDetails.booking_status &&
          this.bookingDetails.booking_status.toLowerCase() === "confirmed"
        ) {
          return "ON THE WAY";
        }
        if (
          this.bookingDetails.booking_status &&
          this.bookingDetails.booking_status.toLowerCase() === "on the way"
        ) {
          return "ON SITE";
        }
        if (
          this.bookingDetails.booking_status &&
          this.bookingDetails.booking_status.toLowerCase() === "on site" &&
          this.pickupFlag
        ) {
          return "PAX ON BOARD";
        }
        if (
          this.bookingDetails.booking_status &&
          this.bookingDetails.booking_status.toLowerCase() === "pax on board"
        ) {
          return "COMPLETED";
        }
        return "";
      }
    },
  },
  methods: {
    async getBookingDetails() {
      const code = this.$route.params.code;
      const response = await Suppliers.getBookingDetails(code);
      if (response.status === 200) {
        const { data } = response;
        console.log("Booking Details:", data.data[0]);
        this.bookingDetails = data.data[0];
        this.driverDetails = data.data[0].driver_details;
        this.tripDetails = data.data[0].booking_services;
      } else {
        console.log("Error fetching booking details:", response);
      }
    },
    async locateMe() {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(this.showPosition);
      }
    },
    showPosition(position) {
      this.latitude = position.coords.latitude;
      this.longitude = position.coords.longitude;
    },
    async checkPickup() {
      if (
        this.bookingDetails.booking_services &&
        this.bookingDetails.booking_services.length > 0
      ) {
        const service = this.bookingDetails.booking_services[0];
        if (service.status && service.status.toLowerCase() === "pickup") {
          this.pickupFlag = true;
        }
      }
    },
    handleSwipeComplete(status) {
      this.currentStatus = status;
      this.showNoShowDialog = true;
    },
    async checkLoggedIn() {
      const user = await AuthenticationHelper.checkLoggedIn();
      if (!user) {
        this.$router.push("/login");
      }
    },
    async saveBookingStatus(status) {
      const response = await Suppliers.updateBookingStatus(this.bookingID, status);
      if (response.status === 200) {
        this.bookingDetails.booking_status = status;
      } else {
        console.log("Error updating booking status:", response);
      }
    },
    async statusBooking() {
      await this.saveBookingStatus(this.setNewStatus);
    },
    handleShowQRCode() {
      this.showQRModal = true;
    },
  },
};
</script>

<style scoped>
.error-popup {
  background-color: #d9534f;
  color: white;
  padding: 10px;
  border-radius: 5px;
}
</style> -->
