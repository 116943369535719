import Repository from './Repository';

class SuppliersRepository {
  constructor() {
    this.resourcePathName = '/guide';
  }

  /**
   * Verifies a given qr code
   * source API Endpoint
   *
   * @param qrCode string
   * @returns {Promise<AxiosResponse<T>>}
   */
  verifyLogin(supplierCode, supplierPassword) {
    return Repository.supplierApiAxios.post(`${this.resourcePathName}/login`,
      {
        username: supplierCode,
        password: supplierPassword,
      });
  }

  getSupplierBookings(supplierID, supplierType, bookingDate) {
    return Repository.supplierApiAxios.post(`${this.resourcePathName}/get_supplier_bookings`,
      {
        supplier_id: supplierID,
        supplier_type: supplierType,
        booking_date: bookingDate,
      });
  }

  getSupplierBookingDetails(bookingID, supplierID) {
    return Repository.supplierApiAxios.post(`${this.resourcePathName}/get_booking_details`,
      {
        guide_id: supplierID,
        booking_id: bookingID,
      });
  }

  updateSupplierBookingStatus(bookingID, supplierID, driverID, bookingStatus, latitude, longitude) {
    return Repository.supplierApiAxios.post(`${this.resourcePathName}/update_booking_status`,
      {
        guide_id: supplierID,
        driver_id: driverID,
        booking_id: bookingID,
        status: bookingStatus,
        lat: latitude,
        lng: longitude,
      });
  }

  updateSupplierBookingServiceStatus(
    bookingID,
    supplierID,
    driverID,
    serviceID,
    serviceStatus,
    latitude,
    longitude,
  ) {
    return Repository.supplierApiAxios.post(`${this.resourcePathName}/update_booking_service_status`,
      {
        guide_id: supplierID,
        driver_id: driverID,
        booking_id: bookingID,
        booking_service_id: serviceID,
        status: serviceStatus,
        lat: latitude,
        lng: longitude,
      });
  }

  updateSupplierBookingServiceNoShow(
    bookingID,
    supplierID,
    driverID,
    serviceID,
    serviceStatus,
    imageBlobs,
    latitude,
    longitude
  ) {
    const data = new FormData();
    imageBlobs.forEach((imageBlob) => {
      data.append('images[]', imageBlob);
    });
    data.append('booking_id', bookingID);
    data.append('guide_id', supplierID);
    data.append('driver_id', driverID);
    data.append('booking_service_id', serviceID);
    data.append('status', serviceStatus);
    data.append('lat', latitude);
    data.append('lng', longitude);
    return Repository.supplierApiAxios.post(`${this.resourcePathName}/booking_service_no_show`, data);
  }
  

  verifyDriverLogin(driverUsername, driverPassword) {
    return Repository.supplierApiAxios.post(`${this.resourcePathName}/driver_login`,
      {
        username: driverUsername,
        password: driverPassword,
      });
  }

  getDriverBookings(driverID, bookingDate) {
    return Repository.supplierApiAxios.post(`${this.resourcePathName}/get_driver_bookings`,
      {
        driver_id: driverID,
        booking_date: bookingDate,
      });
  }

  getDriverBookingDetails(bookingID, driverID,supplierID) {
    return Repository.supplierApiAxios.post(`${this.resourcePathName}/get_driver_booking_details`,
      {
        driver_id: driverID,
        booking_id: bookingID,
        supplier_type: supplierID
      });
  }

  verifyServiceQrCode(serviceQrCode, serviceID, driverID) {
    return Repository.supplierApiAxios.post(`${this.resourcePathName}/booking_service_qrcode_scan`,
      {
        booking_service_id: serviceID,
        driver_id: driverID,
        service_qr_code: serviceQrCode,
      });
  }

  verifySupplierQrcode(QrCode, guideID) {
    return Repository.supplierApiAxios.post(`${this.resourcePathName}/supplier_voucher_redemption_merge`,
      {
        guide_id: guideID,
        supplier_qr_code: QrCode,
      });
  }

  shuttleList(
    driverID,
    bookingDate,
  ) {
   
    return Repository.supplierApiAxios.post('https://app.toureast.net/api/index.php/booking/get_driver_assignment_notification',
      {

        driverId:driverID,
booking_date:bookingDate,

        
      });
      
  }
  shuttleListUpdate(
    bookingStatus,
    latitude,
    longitude,
    driverID,
    bookingDate,
    bookingTime,
   ) {
    return Repository.supplierApiAxios.post('https://app.toureast.net/api/index.php/guide/update_booking_status_group',
      {
        booking_time:bookingTime,
        driver_id:driverID,
        booking_date: bookingDate,
        status: bookingStatus,
        lat: latitude,
        lng: longitude,
        
      });
    
  }
  notifyCustomer(
    clientIDs,
  )
  {
    return Repository.supplierApiAxios.post('https://app.toureast.net/api/index.php/booking/get_user_notification',
      {
        client_ids:  clientIDs
      });
}


  getSupplierProfile(supplierID, supplierType) {
    return Repository.supplierApiAxios.post(`${this.resourcePathName}/get_supplier_profile`,
      {
        supplier_id: supplierID,
        supplier_type: supplierType,
      });
  }

  updateSupplierProfile(supplierID, supplierType, profile) {
    return Repository.supplierApiAxios.post(`${this.resourcePathName}/update_supplier_profile`,
      {
        supplier_id: supplierID,
        supplier_type: supplierType,
        profile,
      });
  }
  getLoginDirecltly(code) {
    return Repository.supplierApiAxios.get(`${this.resourcePathName}/directly-login/${code}`);
  }
  getLoginWithQrCode(code) {
    return Repository.supplierApiAxios.get(`${this.resourcePathName}/login_with_qrcode/${code}`);
  }
  
}

export default new SuppliersRepository();
