
<template>
  <section>
    <div class="text-center m-2"></div>
    <form class="max-w-sm mx-auto font-poppins">
      <div class="mb-2 flex justify-center">
        <PikadayWrapper @calendarDateSelected="calendarDateSelected" :shouldFetchBookings="true"/>
      </div>
      <div v-if="bookingsList.length > 0" class="mb-2">
        <div class="flex justify-center items-center mb-2">
          <input
            type="text"
            v-model="searchKey"
            @blur="searchOnBlur"
            @keyup.enter="search"
            placeholder="Search Keyword"
            class="bg-gray-50 border text-center border-gray-100 text-gray-900 text-sm rounded-md block p-2 px-[70px] focus:outline-none"
          />
        </div>
      </div>
    </form>
    <hr class="mt-2" />
    <div>
      <div v-if="loading" class="flex justify-center items-center mt-24 ">
        <LoadingAnimator :loading="loading" />
      </div>
      <div v-else-if="!loading && !filteredOrders.length">
        <div v-if="selectingDate">
          <div class="flex justify-center items-center mt-14">
            <h1 class="text-xl font-normal text-button">
              No bookings for today!
            </h1>
          </div>
        </div>
        <div v-else>
          <div class="flex justify-center items-center mt-14">
            <h1 class="text-xl font-normal text-button">
              No bookings for selected date!
            </h1>
          </div>
        </div>
      </div>
      <div v-else class="w-full">
        <div v-if="filteredOrders.length > 0">
          <CardDetails
            v-for="(order, index) in filteredOrders"
            :key="index"
            :order="order"
          />
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import PikadayWrapper from "@/components/PikadayWrapper.vue";
import CardDetails from "@/components/driver/CardDetails.vue";
import RepositoryFactory from "../repositories/RepositoryFactory";
import LoadingAnimator from "@/components/LoadingAnimator";
const Suppliers = RepositoryFactory.get("supplier");

export default {
  components: {
    PikadayWrapper,
    CardDetails,
    LoadingAnimator,
  },
  data() {
    return {
      supplierID: this.$store.state.authentication.supplierID,
      supplierType: this.$store.state.authentication.supplierType,
      date: "",
      bookingsList: [],
      searchKey: "",
      loading: true,
      selectingDate: true,
      performSearch: false,
    };
  },
  created() {
    this.getBookings(new Date().toISOString().substr(0, 10));
  },
  methods: {
    async calendarDateSelected(date) {
      this.date = date;
      this.selectingDate = false;
      this.loading = true;
      await this.getBookings(date);
      this.loading = false;
      this.performSearch = false;
    },
    async getBookings(bookingDate) {
      this.loading = true;
      this.bookingsList = [];
      console.log("Fetching bookings for date:", bookingDate);
      const { data } = await Suppliers.getSupplierBookings(
        this.supplierID,
        this.supplierType,
        bookingDate
      );
      console.log("API response:", data);
      if (data.statuscode === process.env.VUE_APP_API_STATUS_SUCCESS) {
        this.bookingsList = data.output[0].booking;
      }
      this.loading = false;
    },
    searchOnBlur() {
      this.performSearch = true;
    },
    search() {
      if (this.performSearch) {
        this.filteredOrders = this.bookingsList.filter((order) => {
          return Object.values(order).some((value) => {
            if (typeof value === "string") {
              return value.toLowerCase().includes(this.searchKey.toLowerCase());
            }
            return false;
          });
        });
        this.performSearch = false;
      }
    },
    navigateBack() {
      this.$router.go(-1);
    },
  },
  computed: {
    selectDateMessage() {
      return this.selectingDate ? "Select a date" : "";
    },
    filteredOrders() {
      if (!this.searchKey) {
        return this.bookingsList;
      }
      return this.bookingsList.filter((order) => {
        return Object.values(order).some((value) => {
          if (typeof value === "string") {
            return value.toLowerCase().includes(this.searchKey.toLowerCase());
          }
          return false;
        });
      });
    },
  },
};
</script>

<style>
input:focus {
  outline: 2px solid #474747;
}
</style>







































<!-- <template>
  <section>
    <div class="text-center m-2"></div>
    <form class="max-w-sm mx-auto  font-poppins">
      <div class="mb-2 flex justify-center">
        <PikadayWrapper @calendarDateSelected="calendarDateSelected" />
      </div>
      <div v-if="bookingsList.length > 0" class="mb-2">
        <div class="flex justify-center items-center mb-2">
          <input
            type="text"
            v-model="searchKey"
            @blur="searchOnBlur"
            @keyup.enter="search"
            placeholder="Search here"
            class="bg-gray-50 border text-center border-gray-100 text-gray-900 text-sm rounded-md block p-2 px-[70px] focus:outline-none"
          />
        </div>
      </div>
    </form>
    <hr class="mt-2" />
    <div>
      <div v-if="loading" class="flex justify-center items-center mt-24 ">
        <loadingAnimator :loading="loading" />
      </div>
      <div v-else-if="!loading && !filteredOrders.length">
        <div v-if="selectingDate">
          <div class="flex justify-center items-center mt-14 ">
            <h1 class="text-xl font-normal text-button">
              No bookings for today!
            </h1>
          </div>
        </div>
        <div v-else>
          <div class="flex justify-center items-center mt-14 ">
            <h1 class="text-xl font-normal text-button">
              No bookings for selected date!
            </h1>
          </div>
        </div>
      </div>
      <div v-else class="w-full ">
        <div v-if="filteredOrders.length > 0">
          <CardDetails
            v-for="(order, index) in filteredOrders"
            :key="index"
            :order="order"
          />
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import PikadayWrapper from "@/components/PikadayWrapper.vue";
import CardDetails from "@/components/CardDetails.vue";
import RepositoryFactory from "../repositories/RepositoryFactory";
import LoadingAnimator from "@/components/LoadingAnimator";
const Suppliers = RepositoryFactory.get("supplier");

export default {
  components: {
    PikadayWrapper,
    CardDetails,
    LoadingAnimator,
  },
  data() {
    return {
      supplierID: this.$store.state.authentication.supplierID,
      supplierType: this.$store.state.authentication.supplierType,
      date: "",
      bookingsList: [],
      searchKey: "",
      loading: true,
      selectingDate: true, 
      performSearch: false, 
    };
  },
  created() {
    this.getBookings(new Date().toISOString().substr(0, 10));
  },
  methods: {
    async calendarDateSelected(date) {
      this.date = date;
      this.selectingDate = false; 
      this.loading = true;
      await this.getBookings(date);
      this.loading = false;
      this.performSearch = false; 
    },
    async getBookings(bookingDate) {
      this.loading = true;
      this.bookingsList = [];
      console.log("Fetching bookings for date:", bookingDate);
      const { data } = await Suppliers.getSupplierBookings(
        this.supplierID,
        this.supplierType,
        bookingDate
      );
      console.log("API response:", data);
      if (data.statuscode === process.env.VUE_APP_API_STATUS_SUCCESS) {
        this.bookingsList = data.output[0].booking;
      }
      this.loading = false;
    },
    searchOnBlur() {
      this.performSearch = true; 
    },
    search(){
      if (this.performSearch) {
        this.filteredOrders = this.bookingsList.filter((order) => {
          return Object.values(order).some((value) => {
            if (typeof value === "string") {
              return value.toLowerCase().includes(this.searchKey.toLowerCase());
            }
            return false;
          });
        });
        this.performSearch = false;
      }
    },
       navigateBack() {

      this.$router.go(-1);
    }
  },
  computed: {
    selectDateMessage() {
      return this.selectingDate ? "Select a date" : "";
    },
    filteredOrders() {
      if (!this.searchKey) {
        return this.bookingsList;
      }
      return this.bookingsList.filter((order) => {
        return Object.values(order).some((value) => {
          if (typeof value === "string") {
            return value.toLowerCase().includes(this.searchKey.toLowerCase());
          }
          return false;
        });
      });
    },
  },
};
</script>

<style>
input:focus {
  outline: 2px solid #474747;
}
</style> -->


