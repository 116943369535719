const AuthenticationHelper = {

  /**
 * Checks if the current user is logged in
 * @param object state vuex object
 * @return bool
 */
  checkUserAuthentication(state) {
    if (state.authentication instanceof Object && (state.authentication.authenticated)) {
      return state.authentication.authenticated;
    }
    return false;
  },

  /**
   * Gets current logged in User
   * @param object state vuex object
   * @return object state Authentication
   */
  getAuthUser(state) {
    return state.authentication;
  },

  /**
   * Gets current logged in user ID
   * @param object Auth state Object
   * @return int clientID
   */
  getSupplierID(auth) {
    return auth.supplierID;
  },

  getDriverID(auth) {
    return auth.driverID;
  },

  /**
   * Gets current logged in user Name
   * @param object Auth state Object
   * @return String clientName
   */
  getSupplierName(auth) {
    return auth.supplierName;
  },
  getDriverName(auth) {
    return auth.driverName;
  },
  /**
   * Gets current logged in user Email
   * @param object Auth state Object
   * @return String clientEmail
   */
  getSupplierEmail(auth) {
    return auth.SupplierEmail;
  },

  getSupplierType(auth) {
    return auth.supplierType;
  },
};

export default AuthenticationHelper;
