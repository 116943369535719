<template>
  <div class="text-gray-700 font-poppins">

    <div class="flex justify-center md:mx-12 mx-4">
      
      <div class="flex bg-button justify-between rounded-lg min-w-full px-3 py-0.1  ">
        <!-- <i class="fas fa-user-circle text-gray-600 text-5xl text-white y-1 mx-1" @click="openModal"></i> -->
        <span class=" pt-2 px-2 text-md md:text-lg text-white font-normal cursor-pointer" @click="openModal">
          {{bookingService.name ? bookingService.name : '' }}
        </span>
        <div class="ml-2 ">
          <h2 class="text-white px-1.5 py-1 m-1 rounded-md  hover:text-white "
          @click="openModal">
          <i class="fa-solid fa-id-card-clip text-2xl"></i>
          </h2> 
        </div>
      </div>
    </div>
    <div v-if="showModal" class="fixed inset-0 z-50 flex justify-center items-center bg-black bg-opacity-50">
      <div class="bg-white w-full h-full flex justify-center items-center">
        <div class="bg-white rounded shadow w-full h-screen mt-4">
          <div class="flex justify-between items-center mt-6 font-normal">
            <h2 class="text-2xl pl-4">Waiting For</h2>
            <button @click="closeModal" class="pr-12 text-gray-500 hover:text-pink-600 text-3xl">
              <i class="fas fa-times"></i>
            </button>
          </div>
          <hr class="my-4" />
          <div class="my-4 mx-auto max-w-screen-lg">
              <div class="flex justify-center  h-screen">
                  <span class="font-normal my-14 md:my-24 text-center">
                      <span class="text-5xl sm:text-6xl md:text-7xl lg:text-8xl xl:text-9xl">
                          {{ bookingService ? bookingService.name : '' }}
                      </span>
                  </span>
              </div>
        </div>
      </div>
    </div>
    </div>
  </div>
</template>

<script>
import { ref } from "vue";

export default {
  props: {
    driverDetails: Object,
    bookingService: Object,
  },
  setup() {
    const showModal = ref(false);

    const openModal = () => {
      showModal.value = true;
    };

    const closeModal = () => {
      showModal.value = false;
    };

    const toggleDetailsVisibility = () => {};

    return {
      showModal,
      openModal,
      closeModal,
      toggleDetailsVisibility,
    };
  },
};
</script>
