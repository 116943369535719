<template>
    <div class="text-center content-center flex justify-center text-red-600 font-semibold my-44 text-sm " >
        <span  v-if="loading">
            <loadingAnimator/>
        </span>
         
      <h1>{{errorMessage}}</h1>
    </div>
  </template>
  
  <script>
  import RepositoryFactory from "../repositories/RepositoryFactory";
  import AuthenticationHelper from "../helpers/AuthenticationHelper";
  import LoadingAnimator from "@/components/LoadingAnimator.vue";
  const Suppliers = RepositoryFactory.get("supplier");
  export default {
    name: "LoginScreen",
    components:{
        LoadingAnimator,
    },
    data() {
      return {
        errorMessage:'',
        qrCode: this.$route.params.code,
        loading:true,
      };
    },
   async created() {
     this.checkLoggedIn();
     
    },
    methods: {
     checkLoggedIn() {
        if (AuthenticationHelper.checkUserAuthentication(this.$store.state)) {
          this.redirectBasedOnRole();
          this.loading=false;
        }else{
            this.verifyUserLogin();
             this.loading=false;
        }
      },
      async verifyUserLogin() {
        try {
          const { data } = await Suppliers.getLoginWithQrCode(this.qrCode);
          if (data.statuscode === process.env.VUE_APP_API_STATUS_SUCCESS) {
            this.$store.commit("supplierLogIn", data.output);
            this.$root.$emit("LoggedIn");
            this.redirectBasedOnRole();
          } else {
            this.errorMessage = "Invalid credentials. Please try again.";
           
          }
        } catch (error) {
          console.error("Error occurred during login:", error);
          this.errorMessage =
            "An error occurred during login. Please try again later.";
        
        }
      },
      redirectBasedOnRole() {
        const role = this.$store.state.authentication.supplierType;
        if (role === "Driver") {
          console.log("role is 1", role);
          localStorage.setItem("current_user_type", "driver");
          this.$router.push({ name: "TodayBooking" });
        } else if (role === "Transport Supplier") {
          console.log("role is 2", role);
          localStorage.setItem("current_user_type", "own vehicle");
          this.$router.push({ name: "TodayBooking" });
        } else {
          console.log("role is 3", role);
          localStorage.setItem("current_user_type", "restuarant");
          this.$router.push({ name: "ScanSupplierCode" });
        }
      },
    },
  };
  </script>
  