import { createApp } from 'vue';
import App from './App.vue';
import store from './store/index.js';
import router from './router';
import 'pikaday/css/pikaday.css';
import "./index.css";
import '@fortawesome/fontawesome-free/css/all.css';
import flatpickr from 'flatpickr';
import 'flatpickr/dist/flatpickr.css';
import VCalendar from 'v-calendar';
import 'v-calendar/style.css';

const app = createApp(App);

app.use(store);
app.use(router);
app.use(flatpickr);
app.use(VCalendar, {});

app.mount('#app');
