import BackofficeRepository from './BackofficeRepository';
import SuppliersRepository from './SuppliersRepository';
import BookingRepository from './BookingRepository';
class RepositoryFactory {
  constructor() {
    this.repositories = {
      supplier: SuppliersRepository,
      backoffice: BackofficeRepository,
      booking: BookingRepository,
    };
  }

  /**
   * Retrieves the API Resource
   *
   * @param resource
   * @return {*}
   */
  get(resource) {
    return this.repositories[resource];
  }
}

export default new RepositoryFactory();
