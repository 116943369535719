// router/index.js
import { createRouter, createWebHistory } from "vue-router";
import store from "@/store/index.js";
import LoginScreen from "@/view/LoginScreen.vue";
import UserProfile from "@/view/UserProfile.vue";
import ShuttleService from "@/view/ShuttleService.vue";
import LoginQrcode from "@/view/LoginQrcode.vue";
import DetailBooking from "@/view/DetailBooking.vue";
import ResetPassword from "@/components/ResetPassword.vue";
import TodayBooking from "@/view/TodayBooking.vue";
import BookingCards from "@/UpdatedScreens/BookingCards.vue";
import DetailDriver from "@/UpdatedScreens/DetailDriver.vue";
import ScanSupplierCode from "@/components/supplier/ScanSupplierCode.vue";
import VerifiedSuccess from "@/components/supplier/VerifiedSuccess.vue";

const routes = [
  { path: "/", name: "LoginScreen", component: LoginScreen },
  {
    path: "/BookingCards",
    name: "BookingCards",
    component: BookingCards,
    meta: { requiresAuth: true },
  },
  {
    path: "/DetailDriver/:code",
    name: "DetailDriver",
    component: DetailDriver,
    meta: { requiresAuth: true },
  },
  {
    path: "/DetailBooking/:code",
    name: "DetailBooking",
    component: DetailBooking,
    meta: { requiresAuth: true },
  },
  {
    path: "/loginQrcode/:code",
    name: "loginQrcode",
    component: LoginQrcode,
  },
  {
    path: "/UserProfile",
    name: "UserProfile",
    component: UserProfile,
    meta: { requiresAuth: true },
  },
  {
    path: "/ResetPassword",
    component: ResetPassword,
    name: "ResetPassword",
    meta: { requiresAuth: true },
  },
  {
    path: "/TodayBooking",
    component: TodayBooking,
    name: "TodayBooking",
    meta: { requiresAuth: true },
  },
  {
    path: "/VerifiedSuccess",
    component: VerifiedSuccess,
    name: "VerifiedSuccess",
    meta: { requiresAuth: true },
  },
  {
    path: "/scan-supplier-code",
    name: "ScanSupplierCode",
    component: ScanSupplierCode,
    title: "Scan Supplier Code",
    meta: {
      showInMenu: true,
      requiresAuth: true,
      roles: ["Guide", "Restaurant"],
    },
  },
  {
    path: "/ShuttleService",
    name: "ShuttleService",
    component: ShuttleService,
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

// Navigation guard
router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (!store.state.authentication.authenticated) {
      next({ name: "LoginScreen" });
    } else {
      next();
    }
  } else {
    next();
  }
});

export default router;
